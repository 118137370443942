<template>
  <div class="container page__inner">
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="isLoading"
      style="width: 100%"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>

    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="!isLoading && showNoData"
      style="width: 100%"
    >
      <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

      <span class="d-block text--danger text--600 text--medium mt-3 mb-5"
        >No data available</span
      >
    </div>

    <CustomerAside
      v-if="
        !isLoading &&
        (accountDetails.accountHolderType == 'CUSTOMERS' ||
          accountDetails.accountHolderType == 'CUSTOMER')
      "
      v-bind:customer-name="accountFullName"
      v-bind:customer-key="accountDetails.accountHolderKey"
      :customerLoanDeposit="customerLoanDeposits"
      :accountKey="savingsAccountKey"
      @imageAlert="imageAlert"
    ></CustomerAside>

    <GroupAside
      v-if="!isLoading && accountDetails.accountHolderType == 'GROUPS'"
      v-bind:group-name="accountFullName"
      v-bind:group-key="accountDetails.accountHolderKey"
      :accountKey="savingsAccountKey"
      :groupLoanDeposit="customerLoanDeposits"
      v-bind:switch-tab-fn-prop="null"
      @imageAlert="imageAlert"
    ></GroupAside>

    <main class="page__content2" v-if="!isLoading && !showNoData">
      <div class="page__head d-flex">
        <div
          class="page__head__container ml-auto align-items-center justify-content-between"
        >
          <div class="page__head__actions">
            <div class="d-flex">
              <!-- <button type="button" class="mx-2 button page__body__header__button button--grey text--capital">
                <span class="d-inline-flex mr-2" data-modal="#requestApproval">Request Approval</span>
              </button> -->
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                @click="openMaturityModal"
                v-if="
                  (this.isSavingPlan &&
                    !this.maturityDateStarted &&
                    !this.isPendingState & !this.isLocked &&
                    !this.isApprovedState &&
                    !this.isWithdrawn &&
                    !this.isRejected &&
                    !this.isDormant &&
                    !this.isMatured &&
                    !this.isClosed) ||
                  (this.isSavingsAccountPlan &&
                    this.isActive &&
                    !this.maturityDateStarted &&
                    this.userCanBeginMaturity)
                "
              >
                <span class="d-inline-flex mr-2" data-modal="#addDepositModal"
                  >Begin Maturity Period</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                @click="openDepositModal"
                v-if="
                  (this.isSavingPlan &&
                    !this.isPendingState &&
                    !this.isLocked &&
                    !this.isWithdrawn &&
                    !this.isRejected &&
                    !this.isMatured &&
                    !this.isClosed) ||
                  (this.isSavingsAccountPlan &&
                    (this.isApprovedState || this.isActive)) ||
                  (this.savingsAccountKey &&
                    this.isDormant &&
                    this.userCanMakeDeposit &&
                    this.isDormant)
                "
              >
                <span class="d-inline-flex mr-2" data-modal="#addDepositModal"
                  >deposit</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                @click="openWithdrawModal"
                v-if="
                  !this.isPendingState &&
                  !this.isApprovedState &&
                  !this.isLocked &&
                  !this.isWithdrawn &&
                  !this.isRejected &&
                  !this.isClosed &&
                  (this.isDormant || this.isActive) &&
                  !this.isWriteOff
                "
              >
                <span class="d-inline-flex mr-2">withdraw</span>
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                @click="openMakeTransferModal"
                v-if="
                  !this.isPendingState &&
                  !this.isApprovedState &&
                  !this.isLocked &&
                  !this.isWithdrawn &&
                  !this.isRejected &&
                  !this.isClosed &&
                  !this.isFixedAccount &&
                  !this.isDormant &&
                  !this.isWriteOff
                "
              >
                <span class="d-inline-flex mr-2">transfer</span>
              </button>
              <div
                class="align-self-center mx-2 dropdown"
                v-if="!this.isActive && !this.isClosed"
              >
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Account Status
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#rejectModal"
                    v-if="
                      !accountStatusList.includes(accountDetails.accountStatus)
                    "
                    @click="openCreateAccountModal(accountDetails)"
                    >Change Account Status</a
                  >
                </div>
              </div>
              <div
                class="align-self-center mx-2 dropdown"
                v-if="!this.isClosed"
              >
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  report
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="showDepositStatementModal"
                    v-if="userCanViewReport"
                    >Account Statement</a
                  >
                </div>
              </div>
              <div
                class="align-self-center mx-2 dropdown"
                v-if="
                  !isSettlementAccount &&
                  !isClosed &&
                  !isApprovedState &&
                  !isPendingState
                "
              >
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="closedMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  close
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="closedMenuButton"
                  v-if="isSavingsAccountPlan"
                >
                  <a
                    class="dropdown-item text--capital"
                    v-if="checkSavingsAccount('close')"
                    @click="closeWriteOffDepositAccount"
                  >
                    Close
                  </a>
                  <a
                    class="dropdown-item text--capital"
                    v-if="checkSavingsAccount('write-off')"
                    @click="closeWriteOffDepositAccount"
                  >
                    Write-Off
                  </a>
                </div>

                <div
                  class="dropdown-menu"
                  aria-labelledby="closedMenuButton"
                  v-else
                >
                  <a
                    class="dropdown-item text--capital"
                    @click="closeWriteOffDepositAccount"
                  >
                    Close
                  </a>
                </div>
              </div>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  more
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#pendingFeesModal"
                    v-if="
                      (this.isSavingPlan &&
                        !this.isApprovedState &&
                        !this.isPendingState &&
                        !this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isMatured &&
                        !this.isClosed) ||
                      (this.isFixedAccount &&
                        this.isDormant &&
                        !this.isMatured &&
                        !this.isWithdrawn) ||
                      (this.isSavingsAccountPlan &&
                        !this.isMatured &&
                        !this.isLocked &&
                        !this.isClosed &&
                        !accountStatusList.includes(
                          accountDetails.accountStatus
                        ))
                    "
                    @click="openNoApplyFeeModal"
                    >Apply Fee</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#accruedInterestModal"
                    v-if="
                      (this.isSavingPlan &&
                        !this.isPendingState &&
                        !this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isApprovedState &&
                        !this.isDormant &&
                        !this.isMatured &&
                        !this.isClosed) ||
                      (this.isSavingsAccountPlan &&
                        this.isActive &&
                        !this.isLocked)
                    "
                    @click="openApplyModal()"
                    >Apply Accrued Interest</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    @click="openAddFieldModal()"
                    v-if="userCanCreateDeposit"
                    >Add Field</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    @click="openEditAccountModal"
                    v-if="userCanEditAccount"
                    >Edit Account</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#recommendDepositModal"
                    @click="openRecommendedModal()"
                    v-if="
                      (!this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isClosed &&
                        !this.isFixedAccount) ||
                      (this.isSavingsAccountPlan && this.isLocked)
                    "
                    >Set Recommended Deposit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#maxWithdrawalModal"
                    @click="openMaxModal()"
                    v-if="
                      !this.isWithdrawn &&
                      !this.isRejected &&
                      !this.isClosed &&
                      !this.isFixedAccount
                    "
                    >Set Max Withdrawal Amount</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#changeHistoryModal"
                    @click="undoBeginMaturity"
                    v-if="
                      this.maturityDateStarted &&
                      !this.isLocked &&
                      !this.isRejected &&
                      this.isMatured &&
                      !this.isClosed &&
                      this.userCanUndoMaturity
                    "
                    >Undo Begin Maturity Period</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#lockAccountModal"
                    @click="openLockModal()"
                    v-if="
                      (!this.isPendingState &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isMatured &&
                        !this.isClosed &&
                        !this.isFixedAccount) ||
                      (this.isActive && this.isLocked)
                    "
                    >{{
                      this.accountDetails.accountStatus === "LOCKED"
                        ? "Unlock Deposit Account"
                        : "Lock Deposit Account"
                    }}</a
                  >
                  <!-- <a
                    v-if="(!isFixedAccount && !isPendingState)"
                    class="dropdown-item text--capital"
                  >
                    View Change History
                  </a> -->
                  <!-- <a
                    class="dropdown-item text--capital"
                    v-if="
                      (this.isPendingState ||
                        this.isApprovedState ||
                        this.isWithdrawn ||
                        this.isRejected ||
                        this.isClosed ||
                        !this.isLocked ||
                        !this.isRejected ||
                        !this.isActive ||
                        !this.isDormant ||
                        !this.isMatured) &&
                      this.isSavingsAccountPlan &&
                      !this.isLocked &&
                      !this.isActive &&
                      !this.isLocked &&
                      !this.isRejected &&
                      !this.isDormant &&
                      !this.isMatured &&
                      userCanDeleteAccount &&
                      accountDetails.accountStatus.toLowerCase() !== 'closed'
                    "
                    @click="deleteDepositAccount()"
                    >Delete</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    v-else-if="
                      userCanDeleteAccount &&
                      accountDetails.accountStatus.toLowerCase() === 'closed'
                    "
                    @click="deleteDepositAccount()"
                    >Delete</a
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page__body">
        <div>
          <div class="page__body__header">
            <ul
              class="nav page__tabs page__tabs2"
              id="page-Tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active text--capital"
                  id="pills-details-tab"
                  data-toggle="tab"
                  href="#pills-details"
                  role="tab"
                  aria-controls="pills-details"
                  aria-selected="true"
                  >details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-transactions-tab"
                  data-toggle="tab"
                  href="#pills-transactions"
                  role="tab"
                  aria-controls="pills-transactions"
                  aria-selected="false"
                  >transactions</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-activities-tab"
                  data-toggle="tab"
                  href="#pills-activities"
                  role="tab"
                  aria-controls="pills-activities"
                  aria-selected="false"
                  >activities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-attachments-tab"
                  data-toggle="tab"
                  href="#pills-attachments"
                  role="tab"
                  aria-controls="pills-attachments"
                  aria-selected="false"
                  >attachments</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-comments-tab"
                  data-toggle="tab"
                  href="#pills-comments"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="false"
                  >comments</a
                >
              </li>
            </ul>
          </div>

          <div class="tab-content" id="page-TabsContent">
            <savingsAccountView
              v-if="isSavingsAccountPlan"
              :accountDetails="accountDetails"
              :accountState="accountActiveState"
              :userCanEditAccount="userCanEditAccount"
              :userCanViewDepositAccount="userCanViewDepositAccount"
              @editField="editCustomField"
            />

            <savingsPlanView
              v-if="isSavingPlan"
              :accountDetails="accountDetails"
              :doesAccountHaveBalance="doesAccountHaveBalance"
              :userCanEditAccount="userCanEditAccount"
              :userCanViewDepositAccount="userCanViewDepositAccount"
              :accountState="accountActiveState"
              @editField="editCustomField"
            />

            <fixedPlanView
              v-if="isFixedAccount"
              :accountDetails="accountDetails"
              :accountState="accountActiveState"
              :userCanEditAccount="userCanEditAccount"
              :userCanViewDepositAccount="userCanViewDepositAccount"
              @editField="editCustomField"
            />

            <settlementAccountView
              v-if="isSettlementAccount"
              :accountDetails="accountDetails"
              :accountState="accountActiveState"
              :userCanEditAccount="userCanEditAccount"
              :userCanViewDepositAccount="userCanViewDepositAccount"
              @editField="editCustomField"
            />

            <!-- Update Deposit Account Status -->
            <createAccount :show="showAccountStatusModal">
              <template v-slot:modal-head>
                <div class="modal__head__icon">
                  <a
                    class="modal__close modal__close--black"
                    @click="closeAccountStatusModal()"
                  >
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </a>
                </div>
              </template>
              <template v-slot:modal-title>
                <h5 class="modal__head__title text--capital">
                  Change Deposit Account State
                </h5>
              </template>
              <template v-slot:modal-body>
                <form>
                  <div>
                    <div class="mb5">
                      <div class="form__item">
                        <label class="form__label">Previous State</label>
                        <p
                          class="text--bold text--black text--regular text--capital"
                        >
                          {{ accountDetails.accountStatus }}
                        </p>
                      </div>
                      <div class="form__item">
                        <label class="form__label">New Account Status</label>
                        <select
                          class="select select--lg"
                          required
                          v-model.trim="account.accountStatus"
                        >
                          <option value="" selected default>
                            Select account type
                          </option>
                          <option value="APPROVED" v-if="isPendingState">
                            APPROVED
                          </option>
                          <!-- <option value="MATURED">MATURED</option>
                          <option value="LOCKED">LOCKED</option>
                          <option value="DORMANT">DORMANT</option> -->
                          <option
                            value="CLOSED"
                            v-if="
                              !checkIfAnyStateIsAtLeastTrue &&
                              !accountDetails.accountStatus.toLowerCase() ===
                                'closed'
                            "
                          >
                            CLOSED
                          </option>
                          <!-- <option value="CLOSED WRITTEN OFF">
                            CLOSED WRITTEN OFF
                          </option> -->
                          <option
                            value="CLOSED REJECTED"
                            v-if="
                              isPendingState ||
                              (!isLocked && !isFixedAccount && isWithdrawn)
                            "
                          >
                            REJECTED
                          </option>
                          <option
                            value="WITHDRAWN"
                            v-if="
                              isPendingState || isApprovedState || isMatured
                            "
                          >
                            WITHDRAW
                          </option>
                        </select>
                      </div>

                      <div class="form__item">
                        <label class="form__label text--regular text--black"
                          >comment</label
                        >
                        <textarea
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Enter comment"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form__item">
                      <div class="form__action">
                        <button
                          class="button form__button form__button--lg w-30 save-changes"
                          v-if="loading"
                        >
                          <div
                            class="spinner-border texxt-light"
                            role="status"
                          ></div>
                        </button>
                        <button
                          class="button form__button form__button--lg save-changes"
                          @click.prevent="changeAccountStatus"
                          v-else
                        >
                          Submit
                        </button>
                        <a
                          data-dismiss="#newDepositModal"
                          role="button"
                          class="modal__close form__action__text form__action__text--danger text--capital ml6"
                          @click="closeAccountStatusModal()"
                        >
                          cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </template>
            </createAccount>

            <!--ALERT NOTIFICATION-->
            <div class="toaster show">
              <div
                class="toaster__container"
                v-if="alert.show"
                v-bind:class="{
                  'toaster--success': alert.status === 'success',
                  'toaster--error': alert.status === 'fail',
                  'toaster--warn': alert.status === 'warn',
                }"
              >
                <div class="text-right">
                  <span class="fa fa-times" @click="alert.show = false"></span>
                </div>
                <div>
                  <span class="d-block text--small text--600 text--white">{{
                    alert.title
                  }}</span>
                  <span class="d-block text--tiny text--400 text--white">{{
                    alert.description
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="pills-transactions"
              role="tabpanel"
              aria-labelledby="pills-transactions-tab"
            >
              <div class="d-flex justify-content-between py-5">
                <label
                  for="showadjusted"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Show Adjusted Transactions
                  <input
                    type="checkbox"
                    id="showadjusted"
                    v-model="transactionStatus"
                    class="form-"
                    @click="changeTransactionStatus()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <hr />
              <table class="customtable w-100">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 14%"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 12%"
                    >
                      Value Date (Entry Date)
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 12%"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 12%"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 12%"
                    >
                      Balance
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 12%"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!userCanViewTransactions">
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center p5"
                      >
                        <img
                          src="@/assets/img/empty.svg"
                          alt="delete"
                          srcset=""
                        />

                        <span
                          class="d-block text--danger text--600 text--medium mt-3 mb-5"
                          >You dont have permission to view transactions</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="!this.transactions.data">
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex align-items-center justify-content-center p5"
                      >
                        <div
                          class="spinner-border"
                          style="width: 3rem; height: 3rem"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span
                          class="text--black text--600 text--medium d-inline-flex ml-4"
                          >Please wait</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="this.transactions.data != ''">
                  <tr
                    v-for="(transaction, i) in this.transactions.data"
                    :key="i"
                  >
                    <td>
                      <span
                        :class="[
                          transaction.isDeleted ? 'text--opacity' : '',
                          'table__body__text table__text text--capital',
                        ]"
                        >{{ transaction.user }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="[
                          transaction.isDeleted ? 'text--opacity' : '',
                          'table__body__text table__text text--capital',
                        ]"
                        >{{ transaction.valuedDate | moment }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="[
                          transaction.isDeleted ? 'text--opacity' : '',
                          'table__body__text table__text text--capital',
                        ]"
                        >{{ transaction.transactionType }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="[
                          transaction.transactionType.toLowerCase() ===
                          'withdrawal'
                            ? 'text--danger'
                            : '',
                          getTransactionStatus(
                            transaction.transactionType,
                            transaction.transactionMode
                          ),
                          transaction.transactionType.toLowerCase() === 'fee'
                            ? 'text--danger'
                            : '',
                          transaction.transactionType.toLowerCase() ===
                          'deposit'
                            ? 'text--success'
                            : '',
                          transaction.isDeleted ? 'text--opacity' : '',
                          'table__body__text table__text text--capital',
                        ]"
                        >{{
                          transaction.amount
                            | currency(accountDetails.currency.currencySymbol)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="[
                          transaction.isDeleted ? 'text--opacity' : '',
                          'table__body__text table__text text--capital',
                        ]"
                        >{{
                          transaction.balance
                            | currency(accountDetails.currency.currencySymbol)
                        }}</span
                      >
                    </td>
                    <td>
                      <div class="align-self-center mx-2 dropdown">
                        <button
                          class="button button--grey text--capital text--small d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          action
                          <span class="dropdown__icon">
                            <ion-icon name="caret-down-outline"></ion-icon>
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item table__body__text table__text text--capital text--primary"
                            @click="
                              openTransactionView(
                                transaction.savingTransactionsKey
                              )
                            "
                            >View</a
                          >
                          <a
                            class="dropdown-item text--capital"
                            @click="
                              deleteTransaction(
                                transaction.savingTransactionsKey
                              )
                            "
                            v-if="showAdjust(transaction.isDeleted, transaction.transactionType)"
                            >Adjust</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center p5"
                      >
                        <img
                          src="@/assets/img/empty.svg"
                          alt="delete"
                          srcset=""
                        />

                        <span
                          class="d-block text--danger text--600 text--medium mt-3 mb-5"
                          >No data available</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                :allItems="transactions"
                :size="size"
                @update-created-task="getUpdatedDeposits"
                @change-page-index="changePageIndex"
              />
              <!-- <div class="pagination">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div class="text--capital text--regular pagination__text">
                    showing
                    <span class="d-inline-block text--primary">1</span> to
                    <span class="d-inline-block text--primary">10</span> of
                    <span>1200</span>
                  </div>
                  <div
                    class="pagination__buttons d-flex align-items-center justify-content-between"
                  >
                    <button class="button button--sm text--regular">
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="button text--regular button--primary text--white text-white button--sm ml-2"
                    >
                      1
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      2
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      3
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      4
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>

            <div
              class="tab-pane fade"
              id="pills-activities"
              role="tabpanel"
              aria-labelledby="pills-activities-tab"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
                v-if="!userCanViewActivities"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >You dont have permission to view activities</span
                >
              </div>
              <div class="pt-3" v-else>
                <div
                  class="form__item"
                  v-for="activity in allActivities"
                  :key="activity.activity_key"
                >
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/edit-icon.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >{{ activity.activity_notes }}</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        {{ activity.creation_date | humanDate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="pills-attachments"
              role="tabpanel"
              aria-labelledby="pills-attachments-tab"
            >
              <div class="mb-3" v-if="userCanUploadAttachment">
                <button
                  class="button button--black d-flex align-items-center ml-auto"
                  @click="openAttachmentModal"
                >
                  <span
                    class="text--capital d-inline-flex mr-2 text-white text--small"
                    >upload new document</span
                  >
                  <img
                    src="../../../../assets/img/upload.svg"
                    alt=""
                    srcset=""
                  />
                </button>
              </div>

              <table class="customtable w-100">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 20%"
                    >
                      Document
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Last Modified
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 20%"
                    >
                      Added By
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!userCanViewAttachment">
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center p5"
                      >
                        <img
                          src="@/assets/img/empty.svg"
                          alt="delete"
                          srcset=""
                        />

                        <span
                          class="d-block text--danger text--600 text--medium mt-3 mb-5"
                          >You dont have permission to view attachments</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="!this.attachments.data">
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex align-items-center justify-content-center p5"
                      >
                        <div
                          class="spinner-border"
                          style="width: 3rem; height: 3rem"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span
                          class="text--black text--600 text--medium d-inline-flex ml-4"
                          >Please wait</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="this.attachments.data != ''">
                  <tr v-for="(attach, i) in this.attachments.data" :key="i">
                    <td>
                      <span
                        class="table__body__text table__text text--capital d-inline-flex align-items-center"
                      >
                        <img
                          src="../../../../assets/img/paper.svg"
                          alt=""
                          srcset=""
                          class="mr-2"
                        />
                        {{ attach.document_title }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ attach.document_type }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ attach.document_file_size | kb }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ attach.creation_date | moment }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ attach.created_by }}</span
                      >
                    </td>
                    <td style="padding-right: 0 !important">
                      <div class="align-self-center mx-2 dropdown">
                        <button
                          class="button button--grey text--capital text--small d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          action
                          <span class="dropdown__icon">
                            <ion-icon name="caret-down-outline"></ion-icon>
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item text--capital"
                            @click="previewFile(attach.document_key)"
                            v-if="userCanViewAttachment"
                            >Preview</a
                          >
                          <a
                            class="dropdown-item text--capital"
                            @click="downloadFile(attach.document_key)"
                            v-if="userCanEditAttachment"
                            >Download</a
                          >
                          <!-- <a class="dropdown-item text--capital">Edit</a> -->
                          <a
                            class="dropdown-item text--capital"
                            @click="
                              openDeleteAttachmentModal(attach.document_key)
                            "
                            v-if="userCanDeleteAttachment"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="12">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center p5"
                      >
                        <img
                          src="@/assets/img/empty.svg"
                          alt="delete"
                          srcset=""
                        />

                        <span
                          class="d-block text--danger text--600 text--medium mt-3 mb-5"
                          >No data available</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="tab-pane fade"
              id="pills-comments"
              role="tabpanel"
              aria-labelledby="pills-comments-tab"
            >
              <div class="mb-3">
                <button
                  class="button button--black d-flex align-items-center ml-auto"
                  data-modal="#addCommentModal"
                  @click="openCommentModal"
                  v-if="userCanCreateComments"
                >
                  <span
                    class="text--capital d-inline-flex mr-2 text-white text--small"
                    >Add new Comments</span
                  >
                  <ion-icon
                    name="add-outline"
                    class="text--600 text--medium text-white"
                  ></ion-icon>
                </button>
              </div>

              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
                v-if="!userCanViewComments"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >You don't have permission to view comments</span
                >
              </div>

              <div v-else>
                <div
                  class="form__item"
                  v-for="comment in comments"
                  :key="comment.id"
                >
                  <div class="row">
                    <div class="col-3">
                      <div class="row no-gutters">
                        <div class="col-1">
                          <img
                            src="../../../../assets/img/comment-green.svg"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-11 px-5">
                          <span
                            class="d-block text--black text--regular text--600"
                            >{{ comment.created_by }}</span
                          >
                          <span
                            class="text--tiny text--dark text-300 text--opaq1"
                          >
                            {{ comment.creationDate | humanDate }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="pr-5">
                        <p class="text--small">
                          {{ comment.comment_note }}
                        </p>
                      </div>
                    </div>
                    <div class="col-3 align-self-center">
                      <div
                        class="pl-5 d-flex align-items-center justify-content-end"
                      >
                        <button
                          type="button"
                          class="mx-2 button button--grey text--capital"
                          @click="openEditCommentModal(comment)"
                          v-if="userCanEditComments"
                        >
                          <span class="d-inline-flex">edit</span>
                        </button>
                        <button
                          type="button"
                          class="mx-2 button button--cancel text--capital"
                          @click="openDeleteModal(comment)"
                          v-if="userCanDeleteComments"
                        >
                          <span class="d-inline-flex">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if="userIsUnauthorized" style="width: 100%">
      <h4 class="text-center mt-5 text-danger">{{ depositError }}</h4>
    </div>

    <DeleteModal
      :show="userIsUnauthorized"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <h5 class="text-danger">
              {{ error }}
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <createAccount
      v-bind:show="showEditAccountModal"
      @close="closeEditAccountModal"
    >
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a
            class="modal__close modal__close--black"
            @click="closeEditAccountModal()"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-title>
        <h5 class="modal__head__title text--capital">Edit Deposit Account</h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div>
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Account Recipient</label
              >
              <p class="text--600 text--capital text--large text--primary">
                {{ accountFullName }}
              </p>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label">Product</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Account Recipient Name"
                    required
                    readonly
                    v-model="accountDetails.productName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ accountDetails.productType }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">currency code</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    readonly
                    placeholder="Currency"
                    v-model="accountDetails.currency.currencyCode"
                  />
                </div>
              </div>
            </div>
            <div class="form__item">
              <p class="text--black text--600 text--capital text--small">
                A standard savings account where a customer may perform regular
                deposit and withdrawals and accrue interest over time.
              </p>
            </div>

            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountTerms"
                  aria-expanded="false"
                  aria-controls="accountTerms"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Account Terms
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountTerms"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div v-show="fixedTermR" class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Interest Rate</label>
                          <div v-if="interestError.length">
                            <div v-for="error in interestError" :key="error.id">
                              <span
                                class="text--tiny d-block text--left text--600 mb-2 text--danger"
                                >{{ error }}</span
                              >
                            </div>
                          </div>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                              >
                                %
                              </span>
                            </div>
                            <input
                              type="number"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter interest rate per month"
                              :min="accountDetails.minimumInterestrate"
                              :max="accountDetails.maximumInterestrate"
                              v-model.number="accountDetails.interestRate"
                            />
                          </div>
                          <small
                            class="text--tiny d-block text--right text--600 mt-2"
                            >Min:
                            {{ accountDetails.minimumInterestrate }}
                            Max:
                            {{ accountDetails.maximumInterestrate }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <!-- {{ account.depositSetting.interestChargeMethod }} -->
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Interest Calculated Using</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{
                              account.depositSetting.calculationAccountBalance
                            }}
                          </p>
                        </div>
                      </div> -->
                      <!-- <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Interest paid into account</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                          </p>
                        </div>
                      </div> -->
                    </div>
                    <div class="row">
                      <div
                        class="col-6"
                        v-if="
                          !['Fixed Deposit', 'FixedDeposit'].includes(
                            accountDetails.productType
                          )
                        "
                      >
                        <div class="form__item">
                          <label class="form__label"
                            >Single Withdrawal Limit</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :value="formattedWithAmount"
                            @input="numberIsValid(formattedWithAmount)"
                            @blur="formatAndValidateDepositWithdrawal($event)"
                          />
                        </div>
                      </div>

                      <div
                        class="col-6"
                        v-if="
                          !['Fixed Deposit', 'FixedDeposit'].includes(
                            accountDetails.productType
                          )
                        "
                      >
                        <div class="form__item">
                          <label class="form__label"
                            >Daily Withdrawal Limit</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :value="formattedDailyAmount"
                            @input="numberIsValid(formattedDailyAmount)"
                            @blur="formatAndValidateDailyAmount($event)"
                          />
                        </div>
                      </div>

                      <div
                        class="col-6"
                        v-if="
                          !['Fixed Deposit', 'FixedDeposit'].includes(
                            accountDetails.productType
                          )
                        "
                      >
                        <div class="form__item">
                          <label class="form__label"
                            >Recommended Deposit Amount</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :value="formattedAmountPaid"
                            @input="numberIsValid(formattedAmountPaid)"
                            @blur="fomartAndValidateDepositAmount($event)"
                          />
                        </div>
                      </div>

                      <div
                        class="col-12"
                        v-if="
                          ['Fixed Deposit', 'FixedDeposit'].includes(
                            accountDetails.productType
                          )
                        "
                      >
                        <div class="form__item">
                          <label class="form__label">Opening Balance</label>
                          <input
                            type="text"
                            :class="[
                              'input input--right',
                              'form__input form__input--lg',
                              openingBalanceErr ? 'input-required' : '',
                            ]"
                            placeholder="Enter Amount"
                            :disabled="
                              ['Fixed Deposit', 'FixedDeposit'].includes(
                                accountDetails.productType
                              )
                            "
                            v-model="accountDetails.openingBalance"
                            @blur="fomartAndValidateDepositAmount($event)"
                          />
                        </div>
                        <small
                          class="text--tiny d-block text--right text--600"
                          style="margin-top: -36px; margin-bottom: 4rem"
                          >Min Open Balance:
                          {{ accountDetails.minimumOpeningBalance }} | Max Open
                          Balance:
                          {{ accountDetails.maximumOpeningBalance }}
                        </small>
                      </div>

                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Branch</label>
                          <multiselect
                            v-model="getAssignedBranchKey"
                            id="ajax"
                            label="branchName"
                            track-by="branchName"
                            placeholder="Type to search branch"
                            open-direction="bottom"
                            :options="branchList"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="5"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            @input="getAccountOfficer"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.branchName }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                          </multiselect>
                          <!-- <select
                            class="select select--lg"
                            required
                            v-model="accountDetails.assignedBranchKey"
                            @change="getAccountOfficer"
                          >
                            <option value="null" disabled selected>
                              Select Branch
                            </option>
                            <option
                              v-for="item in branchList"
                              :key="item.branchKey"
                              :value="item.branchKey"
                            >
                              {{ item.branchName }}
                            </option>
                          </select> -->
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Account Officer</label>
                          <multiselect
                            v-model="getAssignedUserKey"
                            id="ajax"
                            label="name"
                            track-by="name"
                            placeholder="Type to search officer"
                            open-direction="bottom"
                            :options="accountOfficers"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="5"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            @input="getAssignedUser"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                          </multiselect>
                          <!-- <select
                            class="select select--lg"
                            required
                            v-model="accountDetails.assignedUserKey"
                          >
                            <option value="null" disabled selected>
                              Select Account Officer
                            </option>
                            <option
                              v-for="item in accountOfficers"
                              :key="item.userKey"
                              :value="item.userKey"
                            >
                              {{ item.name }}
                            </option>
                          </select> -->
                        </div>
                      </div>

                      <div class="col-6" v-if="isSavingsPlan">
                        <div class="form__item">
                          <label class="form__label">Savings Goal Target</label>
                          <input
                            type="number"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            v-model="accountDetails.savingsGoalTarget"
                            placeholder="Enter Recommended Deposit"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form__item">
                      <label class="form__label">Interest Rate Terms</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                      </p>
                    </div> -->

                    <div v-show="tierBalance" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in accountDetails.tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Balance {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.toTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label"> Amount </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-show="tierPeriod" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in accountDetails.tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Period {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.toTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  Amount for
                                  {{ accountDetails.interestChargeMethod }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Custom Field -->
              <div v-if="accountDetails.customefield.items !== null">
                <div
                  v-for="(cFields, idx) in accountDetails.customefield.items"
                  :key="cFields.fieldSetKey"
                  class="modal__accordion"
                >
                  <li
                    v-if="cFields.fields !== null"
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#${cFields.fieldSetName}`"
                    aria-expanded="false"
                    :aria-controls="cFields.fieldSetName"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      {{ cFields.fieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="cFields.fieldSetName"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="(field, i) in cFields.fields"
                          :key="field.fieldKey"
                        >
                          <div
                            v-if="field.dataType == 'Number'"
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <input
                              type="number"
                              class="input form__input form__input--lg"
                              placeholder="Enter Field value"
                              :required="field.isRequired"
                              @input="
                                editAccountValidateNumber(field.value, idx, i)
                              "
                              v-model="field.value"
                            />
                          </div>
                          <div
                            v-if="field.dataType == 'Checkbox'"
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <input
                              type="checkbox"
                              id="checkbox"
                              class="input form__input form__input--lg"
                              placeholder="Enter Field value"
                              :required="field.isRequired"
                              v-model="field.value"
                            />
                          </div>
                          <div
                            v-if="field.dataType == 'Free text'"
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Field value"
                              :required="field.isRequired"
                              v-model="field.value"
                            />
                          </div>
                          <div
                            v-if="field.dataType == 'Date'"
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <input
                              type="date"
                              class="input form__input form__input--lg"
                              placeholder="Enter Field value"
                              :required="field.isRequired"
                              v-model="field.value"
                            />
                          </div>
                          <div
                            v-if="field.dataType == 'Selection'"
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <select
                              class="select select--lg"
                              :required="field.isRequired"
                              v-model="field.value"
                            >
                              <option value="" disabled selected>
                                Select Field Value
                              </option>
                              <option
                                v-for="filedValue in field.selectionFieldValues"
                                :key="filedValue.customFieldSelectionKey"
                                :value="filedValue.value"
                              >
                                {{ filedValue.value }}
                              </option>
                            </select>
                          </div>
                          <div
                            v-if="
                              [
                                'customer link',
                                'group link',
                                'user link',
                              ].includes(field.dataType.toLowerCase())
                            "
                            class="form__item"
                          >
                            <label
                              style="word-wrap: anywhere"
                              class="form__label"
                              >{{ field.fieldName }}</label
                            >
                            <multiselect
                              v-model="field.value"
                              id="ajax"
                              label="name"
                              track-by="name"
                              :placeholder="`Type to search ${
                                field.dataType.split(' ')[0]
                              }`"
                              open-direction="bottom"
                              :options="searchValue"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="false"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="5"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @search-change="
                                editAccountQuerySearch($event, idx, i)
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#decriptions"
                  aria-expanded="false"
                  aria-controls="decriptions"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="decriptions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Product Descriptions"
                        v-model="accountDetails.savingsAccountNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  class="button form__button form__button--lg save-changes"
                  @click.prevent="updateDepositAccount"
                  v-else
                >
                  Update account
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeEditAccountModal"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </template>
    </createAccount>

    <DeleteModal
      v-bind:show="showDeleteModal"
      v-bind:close="closeDeleteModal"
      @close="closeDeleteModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to delete this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="deleteComment()"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <recommendedModal
      v-bind:show="showRecommendModal"
      @close="closeRecommendModal"
    >
      <template v-slot:modal-body>
        <form>
          <div class="form__item">
            <label class="form__label">Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                >
                  {{ accountDetails.currency.currencySymbol }}
                </span>
              </div>
              <input
                type="text"
                class="input input--right form__input form__input--lg"
                placeholder="Enter Amount"
                :value="formattedAmountPaid"
                @input="numberIsValid(formattedAmountPaid)"
                @blur="formatAndValidateRecommendedAmount($event)"
              />
            </div>
          </div>
          <div class="form__item">
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder=""
                v-model="recommendedAccount.note"
              ></textarea>
            </div>
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg w-30 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#lockAccountModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                @click.prevent="updateRecAmount"
                v-else
              >
                Save Changes
              </button>
              <a
                data-dismiss="#recommendDepositModal"
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeRecommendModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </recommendedModal>

    <maxWithdrawalModal v-bind:show="showMaxModal" @close="closeMaxModal">
      <template v-slot:modal-body>
        <form>
          <div class="form__item">
            <label class="form__label">Maximum withdrawal Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                >
                  {{ accountDetails.currency.currencySymbol }}
                </span>
              </div>
              <input
                type="text"
                class="input input--right form__input form__input--lg"
                placeholder="Enter Amount"
                :value="formattedWithAmount"
                @input="numberIsValid(formattedWithAmount)"
                @blur="formatAndValidateMaxAmount($event)"
              />
            </div>
          </div>
          <div class="form__item">
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder=""
                v-model="maxAccount.note"
              ></textarea>
            </div>
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg w-30 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#maxWithdrawalModal"
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                @click.prevent="updateMaxAmount"
                v-else
              >
                Save Changes
              </button>
              <a
                role="button"
                data-dismiss="#maxWithdrawalModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeMaxModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </maxWithdrawalModal>

    <lockAccount v-bind:show="showLockModal" @close="closeLockModal">
      <template v-slot:modal-body>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label">Previous State</label>
              <p class="text--bold text--black text--regular text--capital">
                {{ accountDetails.accountStatus }}
              </p>
            </div>
            <div class="form__item">
              <label class="form__label">New State</label>
              <p class="text--bold text--success text--regular text--capital">
                {{
                  accountDetails.accountStatus === "LOCKED"
                    ? "Unlocked"
                    : "Locked"
                }}
              </p>
            </div>

            <div class="form__item">
              <label class="form__label text--regular text--black"
                >comment</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                v-model="lockAccount.comment"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg w-30 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#lockAccountModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                @click.prevent="saveLockedStatus"
                v-else
              >
                Change State
              </button>
              <a
                role="button"
                data-dismiss="#lockAccountModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeLockModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </lockAccount>

    <comment v-bind:show="showCommentModal" @close="closeCommentModal">
      <template v-slot:modal-body>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Comments</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                v-model="comment.commentNotes"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addCommentModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                v-else
                @click="showConfirmComment"
              >
                Save changes
              </button>
              <a
                role="button"
                data-dismiss="#addCommentModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeCommentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </comment>

    <comment v-bind:show="showEditCommentModal" @close="closeEditCommentModal">
      <template v-slot:modal-head>
        <h5 class="modal__head__title text--capital">Edit Comment</h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Comments</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                v-model="editComment.comment_note"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addCommentModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                v-else
                @click="showUpdateComment"
              >
                Save changes
              </button>
              <a
                role="button"
                data-dismiss="#addCommentModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeEditCommentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </comment>

    <DeleteModal v-bind:show="showConfirmSave" @close="closeConfirmSave">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to save this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeConfirmSave"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="createComment"
                v-else
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal
      v-bind:show="showUpdateCommentModal"
      @close="closeUpdateComment"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to update this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeUpdateComment"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="onUpdate"
                v-else
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- applied accrued interest -->
    <applyInterest
      v-bind:show="showApplyModal"
      @close="closeApplyModal"
      :accountDetails="accountDetails"
      @accrued-interest-successful="handleInterestAddition"
      @error-accrued-interest-modal="handleInterestFailed"
    ></applyInterest>

    <addField
      :show="showAddField"
      :editingCustomField="editingCustomField"
      @close="closeAddFieldModal"
    >
      <template v-slot:modal-body v-if="!editingCustomField.isEditing">
        <form>
          <div class="form__item">
            <label class="form__label">Field Set</label>
            <select class="select select--lg" required v-model="customField">
              <option value="" disabled selected>Select Field Set</option>
              <option
                v-for="field in allAccountField"
                :key="field.key"
                :value="field"
              >
                {{ field.customFieldSetName }}
              </option>
            </select>
          </div>
          <div class="form__item">
            <label class="form__label">Field Name</label>
            <select
              class="select select--lg"
              @change="
                getFieldValueDataType(customField.customFields, customFieldKey)
              "
              required
              v-model="customFieldKey"
            >
              <option value="" disabled selected>Select Field Name</option>
              <option
                v-for="cField in customField.customFields"
                :key="cField.id"
                :value="cField.customFieldKey"
              >
                {{ cField.customFieldName }}
              </option>
            </select>
          </div>

          <div v-if="fieldDataType == 'Number'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="number"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
              :class="[fieldNumberErr ? 'input-required' : '']"
            />
          </div>
          <div v-if="fieldDataType == 'Checkbox'" class="form__item">
            <label class="form__label">Field Value</label>
            <!-- <div style="display:flex; justify-content:flex-start"> -->
            <input
              type="checkbox"
              id="checkbox"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
            />
            <!-- <label class="form__label" for="checkbox">{{ customFieldValue ? "Selected" : "Not Selected" }}</label>
            </div> -->
          </div>
          <div v-if="fieldDataType == 'Free text'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
            />
          </div>
          <div v-if="fieldDataType == 'Date'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              v-model="customFieldValue"
            />
          </div>
          <div v-if="fieldDataType == 'Selection'" class="form__item">
            <label class="form__label">Field Value</label>
            <select
              class="select select--lg"
              required
              v-model="customFieldValue"
            >
              <option value="" disabled selected>Select Field Value</option>
              <option
                v-for="filedValue in selectionFieldValues"
                :key="filedValue.customFieldSelectionKey"
                :value="filedValue.value"
              >
                {{ filedValue.value }}
              </option>
            </select>
          </div>
          <div
            v-if="
              ['customer link', 'group link', 'user link'].includes(
                fieldDataType.toLowerCase()
              )
            "
            class="form__item"
          >
            <label class="form__label">Field Value</label>
            <multiselect
              v-model="linkFieldValue"
              id="ajax"
              label="name"
              track-by="name"
              :placeholder="`Type to search ${fieldDataType.split(' ')[0]}`"
              open-direction="bottom"
              :options="searchValue"
              :multiple="false"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="5"
              :max-height="800"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="querySearch"
              @input="assignFieldValue"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span>
                </span>
              </template>
            </multiselect>
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addFieldModal"
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="saveCustomField"
              >
                Add Field
              </button>
              <a
                data-dismiss="#addFieldModal"
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAddFieldModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:modal-body v-else>
        <form>
          <div class="form__item">
            <label class="form__label">Field Set</label>
            <select
              class="select select--lg"
              required
              v-model="editingCustomField.customFieldSetKey"
            >
              <option value="" disabled>Select Field Set</option>
              <option
                v-for="field in allAccountField"
                :key="field.customFieldSetKey"
                :value="field.customFieldSetKey"
                :selected="editingCustomField.customFieldSetKey"
                :disabled="!editingCustomField.customFieldSetKey"
              >
                {{ field.customFieldSetName }}
              </option>
            </select>
          </div>
          <div class="form__item">
            <label class="form__label">Field Name</label>
            <select
              class="select select--lg"
              required
              v-model="editingCustomField.customFieldKey"
            >
              <option value="" disabled selected>Select Field Name</option>
              <option
                v-for="cField in editingCustomField.customField"
                :key="cField.fieldKey"
                :value="cField.fieldKey"
                :selected="editingCustomField.fieldKey"
                :disabled="!editingCustomField.fieldKey"
              >
                {{ cField.fieldName }}
              </option>
            </select>
          </div>

          <div v-if="fieldDataType == 'Number'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="number"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Checkbox'" class="form__item">
            <label class="form__label">Field Value</label>
            <!-- <div style="display:flex; justify-content:flex-start"> -->
            <input
              type="checkbox"
              id="checkbox"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <!-- <label class="form__label" for="checkbox">{{ editingCustomField.customFieldValue ? "Selected" : "Not Selected" }}</label>
            </div> -->
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Free text'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Date'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Selection'" class="form__item">
            <label class="form__label">Field Value</label>
            <select
              class="select select--lg"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            >
              <option value="" disabled selected>Select Field Value</option>
              <option
                v-for="filedValue in selectionFieldValues"
                :key="filedValue.customFieldSelectionKey"
                :value="filedValue.value"
              >
                {{ filedValue.value }}
              </option>
            </select>
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div
            v-if="
              ['customer link', 'group link', 'user link'].includes(
                fieldDataType.toLowerCase()
              )
            "
            class="form__item"
          >
            <label class="form__label">Field Value</label>
            <multiselect
              v-model="linkFieldValue"
              id="ajax"
              label="name"
              track-by="name"
              :placeholder="`Type to search ${fieldDataType.split(' ')[0]}`"
              open-direction="bottom"
              :options="searchValue"
              :multiple="false"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="5"
              :max-height="800"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="querySearch"
              @input="editAssignFieldValue"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span>
                </span>
              </template>
            </multiselect>
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <!-- <div class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span>
          </div> -->

          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addFieldModal"
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="updateCustomField"
              >
                Add Field
              </button>
              <a
                data-dismiss="#addFieldModal"
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAddFieldModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </addField>

    <Transfer v-bind:show="showTransferModal" @close="closeMakeTransferModal">
      <template v-slot:transfer>
        <form>
          <div class="row align-items-end">
            <div class="col-6">
              <div class="form__item">
                <span
                  class="text--regular text--600 text--black text--capital d-block"
                  >From Account</span
                >
                <span
                  class="text--small text--600 text--black text--capital d-block"
                  >{{ accountDetails.uniqueId }} -
                  {{ accountDetails.savingsAccountName }}</span
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">To</label>
                <select
                  class="select select--lg"
                  v-model="customerKey"
                  @change="getCustomerAccount()"
                >
                  <option value="" disabled>
                    Select Account
                  </option>
                  <option :value="null" disabled v-if="!getGroupsAndCustomers.length">
                    Loading...
                  </option>
                  <option
                    v-for="customer in getGroupsAndCustomers"
                    :key="customer.id"
                    :value="customer.customerKey || customer.groupKey"
                  >
                    {{ customer.customerFirstName || customer.groupName }}
                    {{ customer.customerLastName || "" }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">To Account</label>
                <select
                  class="select select--lg"
                  v-model="transferAccount.savingAccountKeyTo"
                >
                  <option value="" disabled>Select Account</option>
                  <option
                    v-for="cAccount in customerAccounts"
                    :key="cAccount.id"
                    :value="cAccount.accountKey"
                  >
                    {{ cAccount.accountName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                    >
                      {{ accountDetails.currency.currencySymbol }}
                    </span>
                  </div>
                  <input
                    type="text"
                    class="input input--right form__input form__input--lg"
                    placeholder="Enter Amount"
                    :value="formattedTransferAmount"
                    @input="numberIsValid(formattedTransferAmount)"
                    @blur="formatAndValidateTransfer($event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder=""
                v-model="transferAccount.notes"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="makeTransfer"
              >
                Make Transfer
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeMakeTransferModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </Transfer>

    <Withdraw v-bind:show="showWithdrawalModal" @close="closeWithdrawModal">
      <template v-slot:makeWithdrawal>
        <form>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                    >
                      {{ accountDetails.currency.currencySymbol }}
                    </span>
                  </div>
                  <input
                    type="text"
                    class="input input--right form__input form__input--lg"
                    placeholder="Enter Amount"
                    :value="formattedWithAmount"
                    @input="numberIsValid(formattedWithAmount)"
                    @blur="formatAndValidateWithdrawAmount($event)"
                  />
                  <p>
                    <b
                      >Max withdrawal amount:
                      {{
                        maxWithdrawalAmount
                          | currency(accountDetails.currency.currencySymbol)
                      }}</b
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Channel</label>
                <select
                  class="select select--lg"
                  v-model="makeWithdraw.financialGl"
                >
                  <option value="Select Bank" disabled>Select Bank</option>
                  <option value="Fidelity Bank" selected>Fidelity Bank</option>
                  <option value="Access Bank">Access Bank</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form__item">
            <label
              for="backdate1"
              class="d-block has-checkbox text--black text--500 text--regular"
              >Back date
              <input
                type="checkbox"
                id="backdate1"
                class="form-"
                v-model="withdrawBackDate"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__item" v-show="withdrawBackDate">
            <label class="form__label">Back Date</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              v-model="makeWithdraw.backDate"
            />
          </div>
          <div class="form__item">
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder=""
                v-model="makeWithdraw.notes"
              ></textarea>
            </div>
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                class="button form__button form__button--lg save-changes"
                v-else
                @click.prevent="makeWithdrawalTransaction"
              >
                Make Withdrawal
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeWithdrawModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </Withdraw>

    <makeDeposit v-bind:show="showDepositStatus" @close="closeDepositModal">
      <template v-slot:makeDeposit>
        <form>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                    >
                      {{ accountDetails.currency.currencySymbol }}
                    </span>
                  </div>
                  <input
                    type="text"
                    class="input input--right form__input form__input--lg"
                    placeholder="Enter Amount"
                    :value="formattedAmountPaid"
                    @input="numberIsValid(formattedAmountPaid)"
                    @blur="formatAndValidateDepositAmount($event)"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="form__item">
                <label class="form__label">Channel</label>
                <select
                  class="select select--lg"
                  required
                  v-model="makeDeposit.financialGl"
                >
                  <option value="Select Bank" disabled>Select Bank</option>
                  <option value="Fidelity Bank" selected>Fidelity Bank</option>
                  <option value="Access Bank">Access Bank</option>
                </select>
              </div>
            </div> -->
          </div>
          <div class="form__item">
            <label
              for="backdate"
              class="d-block has-checkbox text--black text--500 text--regular"
              >Back date
              <input
                type="checkbox"
                id="backdate"
                @change="updateBackDateValue"
                class="form-"
                v-model="depositBackDate"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__item" v-show="depositBackDate">
            <label class="form__label">Back Date</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              v-model="makeDeposit.backDate"
            />
          </div>
          <div class="form__item">
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder=""
                v-model="makeDeposit.notes"
              ></textarea>
            </div>
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="makeDepositTransaction"
              >
                Make Deposit
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeDepositModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </makeDeposit>

    <addAttachment
      v-bind:show="showAttachmentStatus"
      @close="closeAttachmentModal"
    >
      <template v-slot:uploadDocument>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label">Title</label>
              <input
                type="text"
                class="input form__input form__input--lg"
                placeholder="Enter Doument title"
                name="title"
                :class="[titleError ? 'input-required' : '']"
                v-model="title"
              />
            </div>
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Description</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                name="description"
                v-model="description"
              ></textarea>
            </div>
            <div class="form__item form__item_dottedbg">
              <div class="p-5 text--center position-relative">
                <input
                  id="attachment"
                  type="file"
                  class="input form__input--lg"
                  accept="*"
                  ref="myFiles"
                  @change="onFileChange()"
                  hidden
                />

                <button
                  class="my-2 button button--black text--500 text--white text--small text--capital"
                  @click.prevent="callFileUpload('attachment')"
                >
                  Choose File
                </button>
              </div>
              <ul class="list-group">
                <li class="list-group-item">
                  {{ filename }} ({{ fileSize | kb }})
                </li>
              </ul>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                class="button form__button form__button--lg save-changes"
                @click.prevent="uploadAttachment"
                v-else
              >
                Save
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAttachmentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </addAttachment>

    <DeleteModal
      v-bind:show="showCloseWriteOff"
      v-bind:close="closeWriteOffModal"
      @close="closeWriteOffModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              {{ closeWriteOff.title }}
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeWriteOffModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                v-else-if="!loading && closeWriteOff.isClosed"
                @click="closeAccountState()"
              >
                {{ closeWriteOff.buttonText }}
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                v-else-if="!loading && closeWriteOff.isWriteOff"
                @click="writeOffAccountState()"
              >
                {{ closeWriteOff.buttonText }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Begin Maturity -->
    <beginMaturity
      :show="showMaturityModal"
      :accountDetails="this.accountDetails"
      @add-maturity-modal="addMaturityModal"
      @error-maturity-modal="errorInMaturity"
      @close="closeMaturityModal"
    />

    <!-- Undo Maturity -->
    <undoMaturity
      :show="showUndoMaturityModal"
      @undo-maturity-modal="undoMaturityModal"
      @error-undo-modal="errorInUndo"
      @close="closeUndoMaturityModal"
    />

    <!--apply Fee Modal --->
    <noApplyFee :show="showNoApplyFee" @close="closeNoApplyFeeModal" />

    <!-- Apply Fee Modal -->
    <applyFee
      :show="showApplyFee"
      @close="closeApplyFeeModal"
      @confirm-apply-fee-successful="saveApplyFee"
      @error-apply-fee-modal="errorAddingFee"
      :accountDetails="this.accountDetails"
    />

    <!-- Attachment -->
    <DeleteModal
      v-bind:show="deleteAttachment"
      @close="closeDeleteAttachmentModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Delete Document
            </h5>
            <p>
              Please confirm that you really want to delete this document
              parmanently
            </p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteAttachmentModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes modal__confirm__button--delete fitrem"
                @click="onAttachmentDelete"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Deposit Statement View -->
    <depositStatement
      :show="showDepositStatement"
      :depositKey="accountStatementKey"
      @close="showDepositStatementModal"
      @statementAlert="imageAlert"
    />

    <!-- Closure Date Modal -->
    <closureDate
      :show="showClosureModal"
      :isLoading="loading"
      @close="closeClosureModal"
      @closeFixedSavingPlanAcc="closeFixedSavingPlanAcc"
    />

    <!-- Transaction View -->
    <transactionView
      :show="showTransactViewModal"
      @close="closeTransactionView"
      :currencySymbol="accountDetails.currency.currencySymbol"
      :accountDetails="this.transactionValue"
    />

    <adjustTransaction
      :show="showAdjustTransactionModal"
      @close="closeAdjustFeeModal"
      :accountDetails="this.selectedTransactionKey"
      :savingsAccountKey="accountDetails.savingsAccountKey"
      :transactionValue="this.transactionValue"
      @adjustfee-success="adjustFee"
      @adjustfee-error="errorAdjustFee"
    />

    <!--Custom field add Modal -->
    <div
      class="modal modal__confirm"
      id="disburseModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      :class="{ show: showDisbursementModal === true }"
      :style="{ display: 'block' }"
      v-if="showDisbursementModal"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                {{ this.showCustomModal.fieldSetName }}
              </span>
              <a
                data-dismiss="#disburseModal"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="closeDisbursementModal"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__content">
            <div class="modal__head border-bottom">
              <h5 class="modal__head__title text--capital">Add Field</h5>
            </div>
            <div class="modal__body">
              <form>
                <div class="form__item">
                  <label class="form__label">Field Name</label>
                  <select
                    class="select select--lg"
                    required
                    v-model="customField"
                  >
                    <option value="" disabled selected>
                      Select Field Name
                    </option>
                    <option
                      v-for="field in showCustomModal.fields"
                      :key="field.id"
                      :value="field"
                    >
                      {{ field.fieldName }}
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Value</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Field value"
                    required
                    v-model="customFieldValue"
                  />
                </div>

                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="button"
                      class="button form__button form__button--lg w-30 save-changes"
                      @click="addFields"
                    >
                      Add Field
                    </button>
                    <a
                      role="button"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                      @click="closeDisbursementModal"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete deposit account -->
    <DeleteModal :show="showDeleteAccountModal" @close="deleteDepositAccount">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Delete Account
            </h5>
            <p>
              Please confirm that you really want to delete this account
              parmanently
            </p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="deleteDepositAccount"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes modal__confirm__button--delete fitrem"
                @click.prevent="onSubmitDelete"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Preview modal -->
    <previewModal
      :show="showPreview"
      :isLoading="loading"
      @close="togglePreviewModal"
      :imagePreviewDataName="imagePreviewDataName"
      :imagePreviewDataType="imagePreviewDataType"
    />
  </div>
</template>
<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import AccessApiService from "@/core/services/access.service";
import CustomerAside from "@/view/pages/clients/customers/loan_deposit_customer_aside.vue";
import GroupAside from "@/view/pages/clients/groups/group_aside.vue";
import createAccount from "@/components/deposit/_base-createAccount.vue";
import recommendedModal from "@/components/deposit/_base-setRecommendedModal.vue";
import maxWithdrawalModal from "@/components/deposit/_base-maxWithdraw.vue";
import lockAccount from "@/components/deposit/_base-lockAccount.vue";
import applyInterest from "@/components/deposit/_base-applyInterest.vue";
import comment from "@/components/deposit/_base-comment.vue";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import addField from "@/components/deposit/_base-addFieldModal.vue";
import Transfer from "@/components/deposit/_base-transfer.vue";
import Withdraw from "@/components/deposit/_base-withdraw.vue";
import makeDeposit from "@/components/deposit/_base-deposit.vue";
import addAttachment from "@/components/deposit/_base-attachment.vue";
import Multiselect from "vue-multiselect";
// import ActiveView from "@/components/deposit/activeStateView.vue";
// import { mapActions, mapGetters } from "vuex";
// import axios from "axios";
import otherService from "@/core/services/other-service/other.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import {
  DELETED_COMMENT,
  CREATED_COMMENT,
  UPDATED_COMMENT,
} from "@/core/services/store/comment.module";
import branchService from "@/core/services/organization-service/branches.service";

import { GET_ALL_TRANSACTIONS } from "@/core/services/store/transactions.module";

import { GET_DEPOSIT_ACCOUNT } from "@/core/services/store/deposit.module";

import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "deposit_view",
  mixins: [currencyMixin],
  components: {
    CustomerAside,
    GroupAside,
    createAccount,
    recommendedModal,
    maxWithdrawalModal,
    lockAccount,
    Multiselect,
    applyInterest,
    comment,
    DeleteModal,
    addField,
    Transfer,
    Withdraw,
    makeDeposit,
    addAttachment,
    savingsAccountView: () =>
      import(
        /* WebpackChunkName: "savingsAccountView" */ "@/components/deposit/views/savingsAccount/savingsAccount.vue"
      ),
    savingsPlanView: () =>
      import(
        /* WebpackChunkName: "savingsPlanView" */ "@/components/deposit/views/savingsPlan/savingsPlan.vue"
      ),
    fixedPlanView: () =>
      import(
        /* WebpackChunkName: "fixedPlanView" */ "@/components/deposit/views/fixedAccount/fixedAccount.vue"
      ),
    settlementAccountView: () =>
      import(
        /* WebpackChunkName: "settlementAccountView" */ "@/components/deposit/views/settlementAccount/settlementAccount.vue"
      ),
    beginMaturity: () =>
      import(
        /* WebpackChunkName: "beginMaturity" */ "@/components/deposit/maturity/_base-maturity.vue"
      ),
    undoMaturity: () =>
      import(
        /* WebpackChunkName: "beginMaturity" */ "@/components/deposit/maturity/_base-confirmModal.vue"
      ),
    noApplyFee: () =>
      import(
        /* WebpackChunkName: "noApplyFee" */ "@/components/deposit/applyFee/noApplyFee.vue"
      ),
    applyFee: () =>
      import(
        /* WebpackChunkName: "applyFee" */ "@/components/deposit/applyFee/applyFee.vue"
      ),
    transactionView: () =>
      import(
        /* WebpackChunkName: "transactionView" */ "@/components/deposit/views/transaction/view.vue"
      ),
    depositStatement: () =>
      import(
        /* WebpackChunkName: "depositStatement" */ "@/components/deposit/deposit-statementModal.vue"
      ),

    closureDate: () =>
      import(
        /* WebpackChunkName: "depositStatement" */ "@/components/deposit/closeAccountDate.vue"
      ),
    adjustTransaction: () =>
      import(
        /* WebpackChunkName: "adjustTransaction" */ "@/components/deposit/views/transaction/adjust.vue"
      ),
    previewModal: () =>
      import(
        /* WebpackChunkName: "previewModal" */ "@/components/deposit/preview/preview.vue"
      ),
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
  },
  data() {
    return {
      allGroups: [],
      accountActiveState: {},
      editingCustomField: {
        customFieldSetKey: "",
        customFieldKey: "",
        customFieldValue: "",
        customField: [],
        isEditing: false,
        isRequired: false,
        isDefault: false,
      },
      savingsAccountKey: "",
      allAccountField: [],
      customFieldError: "",
      customField: [],
      branchKey: "",
      branchName: "",
      loading: false,
      isLoading: false,
      showAccountStatusModal: false,
      showRecommendModal: false,
      showMaxModal: false,
      showLockModal: false,
      showApplyModal: false,
      interestError: [],
      showEditAccountModal: false,
      showEditCommentModal: false,
      showUpdateCommentModal: false,
      showDeleteModal: false,
      showUndoMaturityModal: false,
      showAddField: false,
      showNoApplyFee: false,
      tierBalance: false,
      tierPeriod: false,
      fixedTermR: false,
      showTransferModal: false,
      showWithdrawalModal: false,
      showDepositStatus: false,
      showAttachmentStatus: false,
      showMaturityModal: false,
      showApplyFee: false,
      comments: [],
      commentKey: "",
      customFieldValue: "",
      showConfirmSave: false,
      showDeleteAccountModal: false,
      customFieldKey: "",
      account: {
        accountStatus: "",
        accountKey: "",
      },
      accountState: "",
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      recommendedAccount: {
        savingsAccountKey: this.$route.params.id,
        recommendedDepositAmount: "",
        note: "",
      },
      maxAccount: {
        savingAccountKey: this.$route.params.id,
        maximumWithdrawerAmount: "",
        note: "",
      },
      lockAccount: {
        savingAccountKey: this.$route.params.id,
        comment: "",
        newState: "Locked",
      },
      showCommentModal: false,
      comment: {
        savingAccountKey: this.$route.params.id,
        userKey: "",
        commentNotes: "",
        createdBy: "",
      },
      editComment: [],
      updateComment: {
        commentKey: "",
        commentNote: "",
        modifiedBy: "",
      },
      field: {
        customFieldSetType: "",
        url: "",
      },
      filterCustomers: {
        pageIndex: 1,
        pageSize: 1000,
        filters: [],
      },
      allCustomers: [],
      customerKey: "",
      customerAccounts: [],
      makeDeposit: {
        savingAccountKey: this.$route.params.id,
        amount: "",
        backDate: "",
        financialGl: "",
        notes: "",
      },
      deleteForm: {
        savings_account_key: this.$route.params.id,
      },
      depositBackDate: false,
      makeWithdraw: {
        savingAccountKey: this.$route.params.id,
        amount: "",
        backDate: "",
        financialGl: "",
        notes: "",
      },
      withdrawBackDate: false,
      transferAccount: {
        savingAccountKeyFrom: this.$route.params.id,
        savingAccountKeyTo: "",
        amount: 0,
        backDate: "",
        financialGl: "",
        notes: "",
      },
      allActivities: [],
      showDisbursementModal: false,
      showCustomModal: [],
      customFieldValues: [],
      addField: {
        addField: {
          url: "",
          entityKey: "",
          entityTypeKey: "",
          customFieldValues: [],
        },
      },
      attachments: [],
      currentStatus: "",
      myFiles: null,
      filename: "",
      fileSize: "",
      postAttachment: {
        title: "",
        description: "",
      },
      deposit: {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        currencyCode: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        dailyWaithdrawalLimit: "",
        defaultDepositAmount: "",
        createdBy: "",
        updateField: {
          url: "",
          entityKey: "",
          entityTypeKey: "",
          customFieldValues: [],
        },
        updatedBy: "",
        isDeleted: false,
      },
      groupPage: {
        pageIndex: 1,
        pageSize: 100000000,
        filters: [],
      },
      description: "",
      title: "",
      deleteAttachment: false,
      documentKey: "",
      pages: 1,
      size: 10,
      transactionValue: {},
      showTransactViewModal: false,
      transactionStatus: false,
      selectedTransactionKey: "",
      showAdjustTransactionModal: false,
      attachmentName: "",
      attachmentType: "",
      attachmentKey: "",
      showPreview: false,
      imagePreviewDataName: null,
      imagePreviewDataType: null,
      iconBase64: null,
      customerDeposits: [],
      customerLoanDeposits: [],
      branchList: [],
      accountOfficers: [],
      loadingBranchList: false,
      depositError: "",
      depositErrorCode: "",
      formattedAmountPaid: "",
      formattedWithAmount: "",
      formattedDailyAmount: 0,
      formattedTransferAmount: "",
      formattedRecDepAmount: "",
      showDepositStatement: false,
      accountStatementKey: this.$route.params.id,
      allAdjusted: [],
      fieldDataType: "",
      selectionFieldValues: [],
      searchValue: [],
      linkFieldValue: "",
      fieldNumberErr: false,
      openingBalanceErr: false,
      getAssignedBranchKey: "",
      getAssignedUserKey: "",
      titleError: false,
      closeWriteOff: {
        title: "",
        buttonText: "",
        isClosed: false,
        isWriteOff: false,
      },
      showCloseWriteOff: false,
      showClosureModal: false,
      close_date: "",
    };
  },
  computed: {
    accountFullName: function () {
      if (this.accountDetails.accountHolderType) {
        const { accountHolderType } = this.accountDetails;
        if (accountHolderType && accountHolderType.toLowerCase() == "groups")
          return this.accountDetails.accountFirstName;

        return `${this.accountDetails?.accountFirstName} ${
          this.accountDetails?.accountMiddleName ?? ""
        } ${this.accountDetails?.accountLastName}`;
      }

      return "";
    },
    accountStatusList() {
      let data = [
        "CLOSED",
        "CLOSED WRITTEN OFF",
        "CLOSED REJECTED",
        "WITHDRAWN",
      ];

      return data;
    },
    getSize(size) {
      return size / 1000;
    },
    isSavingsPlan() {
      return this.accountDetails.accountType === "Saving Plan" ||
        this.accountDetails.accountType === "SAVING PLAN" ||
        this.accountDetails.accountType === "SAVINGS PLAN"
        ? true
        : false;
    },
    isPendingState() {
      return this.accountDetails.accountStatus === "PENDING APPROVAL"
        ? true
        : false;
    },
    isApprovedState() {
      return this.accountDetails.accountStatus === "APPROVED" ? true : false;
    },
    isWithdrawn() {
      return this.accountDetails.accountStatus === "WITHDRAWN" ? true : false;
    },
    isRejected() {
      return this.accountDetails.accountStatus === "CLOSED REJECTED"
        ? true
        : false;
    },
    isWriteOff() {
      return this.accountDetails.accountStatus === "CLOSED WRITTEN OFF"
        ? true
        : false;
    },
    isActive() {
      return this.accountDetails.accountStatus === "ACTIVE IN ARREARS" ||
        this.accountDetails.accountStatus === "ACTIVE"
        ? true
        : false;
    },
    isLocked() {
      return this.accountDetails.accountStatus === "LOCKED";
    },
    isDormant() {
      return this.accountDetails.accountStatus === "DORMANT" ? true : false;
    },
    isMatured() {
      return this.accountDetails.accountStatus === "MATURED" ? true : false;
    },
    isClosed() {
      return this.accountDetails.accountStatus === "CLOSED" ? true : false;
    },
    checkIfAnyStateIsAtLeastTrue() {
      return (
        this.isPendingState ||
        this.isApprovedState ||
        this.isWithdrawn ||
        this.isRejected ||
        this.isActive ||
        this.isLocked ||
        this.isDormant ||
        this.isMatured
      );
    },
    productType() {
      return this.accountDetails.productType
        ? this.accountDetails.productType.toUpperCase()
        : "";
    },
    isSavingsAccountPlan() {
      return this.productType === "SAVING ACCOUNT" ||
        this.productType === "SAVINGACCOUNT" ||
        this.productType === "SAVINGSACCOUNT" ||
        this.productType === "SAVINGS ACCOUNT"
        ? true
        : false;
    },
    isSavingPlan() {
      return this.productType === "SAVING PLAN" ||
        this.productType === "SAVINGPLAN" ||
        this.productType === "SAVINGSPLAN" ||
        this.productType === "SAVINGS PLAN"
        ? true
        : false;
    },
    isSettlementAccount() {
      return this.productType.toLowerCase() === "settlementaccount" ||
        this.productType.toLowerCase() === "settlement account"
        ? true
        : false;
    },
    isFixedAccount() {
      return this.productType === "FIXEDDEPOSIT" ||
        this.productType === "FIXED DEPOSIT"
        ? true
        : false;
    },
    isApplyFeeActive() {
      return this.accountDetails.productFee.length > 0 ? true : false;
    },
    doesAccountHaveBalance() {
      return this.accountDetails.balance > 0;
    },
    recommendedDepositAmount() {
      return this.accountDetails.recommendedDepositAmount;
    },
    maxWithdrawalAmount() {
      return this.accountDetails.maxWithdrawalAmount;
    },
    maturityDateStarted() {
      return this.accountDetails.maturityDate !== null;
    },
    isDataImage() {
      return (
        this.attachmentType === "image/jpeg" ||
        this.attachmentType === "image/gif" ||
        this.attachmentType === "image/svg+xml"
      );
    },
    accountKey() {
      return this.accountDetails.accountHolderKey;
    },
    customFieldHasError() {
      return this.customFieldError !== null;
    },
    getGroupsAndCustomers() {
      if (this.allCustomers.length && this.allGroups.length) {
        return [...this.allCustomers, ...this.allGroups];
      }
      return [];
    },
    ...mapState({
      accountDetails: (state) => {
        // console.log(state.deposit.depositAccount.data)
        return state.deposit.depositAccount.data;
      },
      transactions: (state) => state.transactions.allTransactions,
      errorCode: (state) => state.deposit.depositErrorCode,
      error: (state) => state.deposit.depositError,
      showNoData: (state) => state.deposit.showNoData,
      user: (state) => state.auth.user,
    }),
    userIsUnauthorized() {
      return this.errorCode == 409 || !this.userCanViewDepositAccount;
    },
    userCanViewDepositAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanViewSavingsAccount;
    },
    userCanBeginMaturity() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanActivateMaturity;
    },
    userCanUndoMaturity() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanUndoMaturity;
    },
    userCanCreateField() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = account;
      return permissionsList.CanCreateCustomField;
    },
    userCanMakeDeposit() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanMakeDeposit;
    },
    userCanEditAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanEditSavingsAccount;
    },
    userCanCreateDeposit() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = deposit;
      return permissionsList.CanCreateSavingsAccount;
    },
    userCanDeleteAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanDeleteSavingsAccount;
    },
    userCanUploadAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanCreateDocuments;
    },
    userCanEditAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanEditDocuments;
    },
    userCanDeleteAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanDeleteDocuments;
    },
    userCanViewAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanViewDocuments;
    },
    userCanViewReport() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Reports
      );
      const { permissionsList } = document;
      return permissionsList.CanViewReports;
    },
    userCanViewActivities() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Activity
      );
      const { permissionsList } = document;
      return permissionsList.CanViewActivities;
    },
    userCanViewTransactions() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanViewSavingsTransactions;
    },
    userCanViewComments() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = account;
      return permissionsList.CanViewComments;
    },
    userCanCreateComments() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = account;
      return permissionsList.CanCreateComments;
    },
    userCanEditComments() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = account;
      return permissionsList.CanEditComments;
    },
    userCanDeleteComments() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = account;
      return permissionsList.CanDeleteComments;
    },
  },
  methods: {
    showAdjust(isDeleted, type) {
      return !isDeleted && type.toLowerCase() !== 'transfer';
    },
    checkSavingsAccount(type) {
      if (type === "write-off") {
        if (this.isSavingsAccountPlan && this.accountDetails.balance < 0) {
          return true;
        } else {
          return false;
        }
      }
      if (type === "close") {
        if (this.isSavingsAccountPlan && this.accountDetails.balance < 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    closeWriteOffDepositAccount() {
      if (this.isSavingsAccountPlan) {
        if (this.accountDetails.balance > 0) {
          this.alertWarning(
            "This account cannot be closed because its balance is greater than zero."
          );
          return;
        }

        if (this.accountDetails.balance == 0) {
          this.closeWriteOff = {
            title: "Are you show you want to set account state to Close?",
            buttonText: "Close",
            isClosed: true,
            isWriteOff: false,
          };
          this.showCloseWriteOff = true;
          return;
        }

        if (this.accountDetails.balance < 0) {
          this.closeWriteOff = {
            title: "Are you show you want to set account state to Write Off?",
            buttonText: "Write Off",
            isClosed: false,
            isWriteOff: true,
          };
          this.showCloseWriteOff = true;
          return;
        }
      } else {
        this.showClosureModal = true;
      }
    },
    closeClosureModal() {
      this.close_date = "";
      this.showClosureModal = false;
    },
    closeFixedSavingPlanAcc(date) {
      this.close_date = date;
      if (this.accountDetails.balance < 0) {
        this.alertWarning(
          "This type of account cannot be closed with a negative balance."
        );
        return;
      } else {
        this.loading = true;
        ApiService.post(`DepositAccount/CloseDepositAccount`, {
          savingsAccountKey: this.savingsAccountKey,
          userKey: "",
          closeDate: moment(this.close_date).format("YYYY-MM-DD hh:mm:ss"),
        })
          .then(() => {
            this.loading = false;
            this.alertSuccess("Account Successfully closed.");
            this.getAccountDetails();
            this.closeClosureModal();
            // this.alertSuccess(response.data.message)
          })
          .catch((err) => {
            this.loading = false;
            const message = err.response.data.message;
            this.alertError(message);
          });
      }
    },
    closeWriteOffModal() {
      this.closeWriteOff = {
        title: "",
        buttonText: "",
        isClosed: false,
        isWriteOff: false,
      };
      this.showCloseWriteOff = false;
    },
    closeAccountState() {
      this.loading = true;
      ApiService.post(`DepositAccount/CloseDepositAccount`, {
        savingsAccountKey: this.savingsAccountKey,
        userKey: "",
        closeDate: moment().format("YYYY-MM-DD hh:mm:ss"),
      })
        .then(() => {
          this.closeWriteOffModal();
          this.loading = false;
          this.alertSuccess("Account Successfully closed.");
          this.getAccountDetails();
          // this.alertSuccess(response.data.message)
        })
        .catch((err) => {
          this.loading = false;
          const message = err.response.data.message;
          this.alertError(message);
        });
    },
    writeOffAccountState() {
      this.loading = true;
      ApiService.post(`DepositAccount/CloseDepositAccount`, {
        savingsAccountKey: this.savingsAccountKey,
        userKey: "",
        closeDate: moment().format("YYYY-MM-DD hh:mm:ss"),
      })
        .then(() => {
          this.closeWriteOffModal();
          this.loading = false;
          this.alertSuccess("Account Successfully written off.");
          this.getAccountDetails();
          // this.alertSuccess(response.data.message)
        })
        .catch((err) => {
          this.loading = false;
          const message = err.response.data.message;
          this.alertError(message);
        });
    },
    getCustomerDeposits(key) {
      this.loading = true;
      this.customerDeposits = [];
      ApiService.get(`DepositAccount/GetDepositAccountByCustomer/${key}`).then(
        (response) => {
          this.customerDeposits = response.data.data;
          this.loading = false;
        }
      );
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    showDepositStatementModal() {
      this.showDepositStatement = !this.showDepositStatement;
    },
    numberIsValidFD() {
      if (!this.accountDetails.openingBalance) {
        this.alertWarning("Opening balance is required.");
        return true;
      }
      if (
        !isNaN(parseFloat(this.accountDetails.openingBalance)) &&
        isFinite(this.accountDetails.openingBalance)
      ) {
        if (
          parseFloat(this.accountDetails.openingBalance) <
          this.accountDetails.minimumOpeningBalance
        ) {
          this.alertWarning(
            "Opening Balance Amount cannot be less than the minimum opening balance value."
          );
          this.openingBalanceErr = true;
          return true;
        } else if (
          parseFloat(this.accountDetails.openingBalance) >
          this.accountDetails.maximumOpeningBalance
        ) {
          this.alertWarning(
            "Opening Balance Amount cannot be greater than the maximum opening balance value."
          );
          this.openingBalanceErr = true;
          return true;
        } else {
          this.openingBalanceErr = false;
          return false;
        }
      } else {
        this.alertWarning("Invalid input type, number required.");
        this.openingBalanceErr = true;
        return true;
      }
    },
    fomartAndValidateDepositAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.accountDetails.recommendedDepositAmount = amtPaid;
        this.formattedAmountPaid = amountInputted;
        return;
      }
      this.accountDetails.recommendedDepositAmount =
        this.accountDetails.defaultDepositAmount;
      this.formattedAmountPaid = "";
    },
    formatAndValidateDepositWithdrawal(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.accountDetails.maxWithdrawalAmount = amtPaid;
        this.formattedWithAmount = amountInputted;
        return;
      }
      this.accountDetails.maxWithdrawalAmount =
        this.accountDetails.maxWithdrawalAmount;
      this.formattedWithAmount = "";
    },
    formatAndValidateDailyAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.accountDetails.dailyWaithdrawalLimit = amtPaid;
        this.formattedDailyAmount = amountInputted;
        return;
      }
      this.accountDetails.dailyWaithdrawalLimit = this.accountDetails.dailyWaithdrawalLimit;
      this.formattedDailyAmount = "";
    },
    formatAndValidateTransfer(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.transferAccount.amount = amtPaid;
        this.formattedTransferAmount = amountInputted;
        return;
      }
      this.transferAccount.amount = this.transferAccount.amount;
      this.formattedTransferAmount = "";
    },
    formatAndValidateRecommendedAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.recommendedAccount.recommendedDepositAmount = amtPaid;
        this.formattedAmountPaid = amountInputted;
        return;
      }
      this.recommendedAccount.recommendedDepositAmount =
        this.recommendedAccount.recommendedDepositAmount;
      this.formattedAmountPaid = "";
    },
    formatAndValidateMaxAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.maxAccount.maximumWithdrawerAmount = amtPaid;
        this.formattedWithAmount = amountInputted;
        return;
      }
      this.maxAccount.maximumWithdrawerAmount =
        this.maxAccount.maximumWithdrawerAmount;
      this.formattedWithAmount = "";
    },
    formatAndValidateWithdrawAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.makeWithdraw.amount = amtPaid;
        this.formattedWithAmount = amountInputted;
        return;
      }
      this.makeWithdraw.amount = this.makeWithdraw.amount;
      this.formattedWithAmount = "";
    },
    formatAndValidateDepositAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.makeDeposit.amount = amtPaid;
        this.formattedAmountPaid = amountInputted;
        return;
      }
      this.makeDeposit.amount = this.makeDeposit.amount;
      this.formattedAmountPaid = "";
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService.get({
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      });
    },
    async getCustomerLoanDeposits(key) {
      this.isLoading = true;
      try {
        const res = await otherService.getAllLoanDepositAccount(key);
        this.customerLoanDeposits = res.data.data;
        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.closedAccount.length > 0
        ) {
          this.customerLoanDeposits.closedAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }

        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.operationalAccount.length > 0
        ) {
          this.customerLoanDeposits.operationalAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    resetCustomField() {
      this.customField = [];
      this.fieldDataType = "";
      this.selectionFieldValues = [];
      this.linkFieldValue = "";
      this.searchValue = [];
      this.customFieldKey = "";
      this.editingCustomField = {
        customFieldSetKey: "",
        customFieldKey: "",
        customFieldValue: "",
        customField: "",
        isEditing: false,
        isRequired: false,
        isDefault: false,
      };
      this.editingCustomField.editingCustomField = false;
    },
    deleteDepositAccount() {
      this.showDeleteAccountModal = !this.showDeleteAccountModal;
    },
    onSubmitDelete() {
      this.loading = true;
      let savings_account_key = this.$route.params.id;
      ApiService.delete(
        `DepositAccount/DeleteDepositAccount?savingsAccountKey=${savings_account_key}`
      )
        .then((response) => {
          this.loading = false;
          this.alertSuccess(response.data.message);
          this.deleteDepositAccount();
          this.$router.push({ path: "/deposit-accounts" });
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    getState() {
      this.accountActiveState = {};
      this.savingsAccountKey = this.$route.params.id;
      ApiService.get(
        `DepositAccount/DepositAccountManagementView/${this.savingsAccountKey}`
      ).then((response) => {
        this.accountActiveState = response.data.data;
      });
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    getAccountDetails() {
      this.savingsAccountKey = this.$route.params.id;
      this.isLoading = true;
      if (this.savingsAccountKey) {
        this.$store
          .dispatch(GET_DEPOSIT_ACCOUNT, {
            savingsAccountKey: this.savingsAccountKey,
          })
          .then(() => {
            this.isLoading = false;
          });

        if (!this.userCanViewDepositAccount)
          return this.alertWarning(
            "You do not have permission to view this account"
          );
      } else {
        this.goToDashboard();
      }
    },
    getBranchDetails() {
      this.branchName = "";
      GeneralApiService.get(`Branch/${this.branchKey}`).then((resp) => {
        this.branchName = resp.data.data;
      });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    changeAccountStatus() {
      this.loading = true;
      ApiService.put(`DepositAccount/UpdateDepositAccountStatus`, this.account)
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.getBranchDetails();
        })
        .catch((e) => {
          this.alertError(e.response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.showAccountStatusModal = false;
          this.getAccountDetails();
          this.getBranchDetails();
          this.getState();
        });
    },
    updateBackDateValue() {
      if (!this.depositBackDate) {
        this.makeDeposit.backDate = "";
      }
    },
    openCreateAccountModal(accountDetails) {
      this.showAccountStatusModal = true;
      this.accountState = accountDetails.accountStatus;
      this.account.accountKey = accountDetails.savingsAccountKey;
    },
    closeAccountStatusModal() {
      this.showAccountStatusModal = false;
      this.accountStatus = "";
    },
    openRecommendedModal() {
      this.showRecommendModal = true;
      this.recommendedAccount.recommendedDepositAmount =
        this.recommendedDepositAmount;
    },
    closeRecommendModal() {
      this.showRecommendModal = false;
    },
    openLockModal() {
      this.showLockModal = true;
    },
    closeLockModal() {
      this.showLockModal = false;
    },
    openMaxModal() {
      this.showMaxModal = true;
      this.maxAccount.maximumWithdrawerAmount = this.maxWithdrawalAmount;
    },
    closeMaxModal() {
      this.showMaxModal = false;
    },
    openApplyModal() {
      this.showApplyModal = true;
    },
    closeApplyModal() {
      this.showApplyModal = false;
    },
    closeApplyFeeModal() {
      this.showApplyFee = false;
    },
    async openEditAccountModal() {
      this.searchValue = [];
      await this.accountDetails.customefield.items.forEach(async (item) => {
        const getAccount = this.allAccountField.find(
          (acc) => acc.customFieldSetKey == item.fieldSetKey
        );
        for (let i = 0; i < item.fields.length; i++) {
          if (item.fields[i].dataType == "Selection") {
            item.fields[i].selectionFieldValues = getAccount.customFields.find(
              (field) => field.customFieldKey == item.fields[i].fieldKey
            ).customFieldSelections;
          }

          if (
            ["customer link", "group link", "user link"].includes(
              item.fields[i].dataType.toLowerCase()
            )
          ) {
            let searchArea =
              item.fields[i].dataType.toLowerCase() == "customer link"
                ? "customer"
                : item.fields[i].dataType.toLowerCase() == "group link"
                ? "group"
                : "user";
            let linkName = item.fields[i].linkName
              ? item.fields[i].linkName
              : null;
            let linkKey = item.fields[i].linkKey
              ? item.fields[i].linkKey
              : null;
            let searchQuery = item.fields[i].linkName
              ? item.fields[i].linkName.split(" ")[0]
              : "";
            try {
              const response = await ApiService.post(`GlobalSearch`, {
                searchQuery,
                searchCategory: searchArea,
              });
              this.searchValue = response.data.data ? response.data.data : [];
              item.fields[i].value = {};
              if (linkName && linkKey) {
                item.fields[i].value = {
                  ...item.fields[i].value,
                  name: linkName,
                  linkKey: linkKey,
                };
              } else {
                item.fields[i].value = null;
              }
            } catch (e) {
              this.searchValue = [];
            }
          }
        }
      });

      const maxAmount = {
        target: { value: `${this.accountDetails.maxWithdrawalAmount}` },
      };
      const dailyWithdrawal = {
        target: { value: `${this.accountDetails.dailyWaithdrawalLimit}` },
      };

      this.formatAndValidateDepositWithdrawal(maxAmount);
      this.formatAndValidateDailyAmount(dailyWithdrawal);

      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.accountDetails.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
        this.getAssignedUserKey = this.accountOfficers.find(
          (officer) => officer.userKey == this.accountDetails.assignedUserKey
        );
      });
      this.getAssignedBranchKey = this.branchList.find(
        (branch) => branch.branchKey == this.accountDetails.assignedBranchKey
      );
      this.showEditAccountModal = true;

      if (
        this.accountDetails.interestRateTerm === "Tiered per balance" ||
        this.accountDetails.interestRateTerm === "Tier per balance"
      ) {
        this.tierBalance = true;
      } else if (this.accountDetails.interestRateTerm === "Fixed") {
        this.fixedTermR = true;
      } else if (
        this.accountDetails.interestRateTerm === "Tiered per period" ||
        this.accountDetails.interestRateTerm === "Tier per period"
      ) {
        this.tierPeriod = true;
      }
    },
    closeEditAccountModal() {
      this.showEditAccountModal = false;
    },
    resetRecommendedForm() {
      this.recommendedAccount = {
        savingsAccountKey: this.$route.params.id,
        recommendedDepositAmount: "",
        note: "",
      };
    },
    resetWithdrawalForm() {
      this.maxAccount = {
        savingAccountKey: this.$route.params.id,
        maximumWithdrawerAmount: "",
        note: "",
      };
    },
    resetLockForm() {
      this.lockAccount = {
        savingAccountKey: this.$route.params.id,
        comment: "",
        newState: "Locked",
      };
    },
    resetTransferForm() {
      this.transferAccount = {
        savingAccountKeyFrom: this.$route.params.id,
        savingAccountKeyTo: "",
        amount: 0,
        backDate: "",
        financialGl: "",
        notes: "",
      };
      this.formattedTransferAmount = "";
    },
    updateRecAmount() {
      this.loading = true;
      ApiService.put(
        `DepositAccount/UpdateRecommendedDepositAmount`,
        this.recommendedAccount
      )
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loading = false;
          this.showRecommendModal = false;
          this.formattedAmountPaid = "";
          this.getAccountDetails();
          this.resetRecommendedForm();
          this.getState();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.loading = false;
        });
    },
    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }

      if (type === "success") {
        this.alertSuccess(message);
      }

      if (type === "warning") {
        this.alertWarning(message);
      }
    },
    updateMaxAmount() {
      this.loading = true;
      ApiService.put(
        `DepositAccount/UpdateMaximumWithdrawerAmount`,
        this.maxAccount
      )
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loading = false;
          this.showMaxModal = false;
          this.formattedWithAmount = "";
          this.getAccountDetails();
          this.resetWithdrawalForm();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.loading = false;
        });
    },
    saveLockedStatus() {
      this.loading = true;
      ApiService.put(
        `DepositAccount/LockUnlockDepositAccountStatus`,
        this.lockAccount
      )
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loading = false;
          this.showLockModal = false;
          this.getAccountDetails();
          this.resetLockForm();
        })
        .catch((e) => {
          this.alertError(e.response.data.message);
          this.loading = false;
        });
    },
    openDeleteModal(comment) {
      this.showDeleteModal = true;
      this.commentKey = comment.comment_key;
    },
    deleteComment() {
      let commentKey = this.commentKey;
      this.loading = true;
      this.$store
        .dispatch(DELETED_COMMENT, { commentKey })
        .then(() => {
          this.alertSuccess("Comment deleted successfully!");
          this.loading = false;
          this.showDeleteModal = false;
          this.getComment();
        })
        .catch((error) => {
          this.alertError(error);
          this.loading = false;
        });
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    openCommentModal() {
      this.showCommentModal = true;
    },
    closeCommentModal() {
      this.showCommentModal = false;
    },
    getComment() {
      this.comments = [];
      ApiService.get(
        `DepositAccount/GetComment?savingsAccountKey=${this.$route.params.id}&pageIndex=1&pageSize=10`
      )
        .then((resp) => {
          this.comments = resp.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    resetCommentForm() {
      this.comment = {
        savingAccountKey: this.$route.params.id,
        userKey: "",
        commentNotes: "",
        createdBy: "",
      };
    },
    createComment() {
      this.loading = true;
      this.$store
        .dispatch(CREATED_COMMENT, this.comment)
        .then(() => {
          this.loading = false;
          let desc = "Comment succesfully created!";
          this.alertSuccess(desc);
          this.closeConfirmSave();
          this.closeCommentModal();
          this.getActivities();
          this.getComment();
          this.resetCommentForm();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
          this.closeConfirmSave();
        });
    },
    showConfirmComment() {
      this.showConfirmSave = true;
    },
    closeConfirmSave() {
      this.showConfirmSave = false;
    },
    openEditCommentModal(comment) {
      this.showEditCommentModal = true;
      this.editComment = [];
      let commentKey = comment.comment_key;
      ApiService.get(`DepositAccount/GetCommentByCommentKey/${commentKey}`)
        .then((response) => {
          this.editComment = response.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    closeEditCommentModal() {
      this.showEditCommentModal = false;
    },
    showUpdateComment() {
      this.showUpdateCommentModal = true;
    },
    closeUpdateComment() {
      this.showUpdateCommentModal = false;
    },
    onUpdate() {
      this.loading = true;
      this.updateComment.commentKey = this.editComment.comment_key;
      this.updateComment.commentNote = this.editComment.comment_note;
      this.$store
        .dispatch(UPDATED_COMMENT, this.updateComment)
        .then(() => {
          this.loading = false;
          let desc = "Comment succesfully updated!";
          this.alertSuccess(desc);
          this.closeEditCommentModal();
          this.closeUpdateComment();
          this.getAccountDetails();
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
          this.closeUpdateComment();
        });
    },
    updateDepositAccount(e) {
      this.deposit.updateField.customFieldValues = [];
      if (this.accountDetails.customefield.items.length) {
        this.accountDetails.customefield.items.forEach((item) => {
          for (let i = 0; i < item.fields.length; i++) {
            if (
              ["customer link", "group link", "user link"].includes(
                item.fields[i].dataType.toLowerCase()
              )
            ) {
              if (item.fields[i].value) {
                const updateValue = item.fields[i].value;
                item.fields[i].value = updateValue.linkKey;
              }
            }
            let customObj = {
              value: item.fields[i].value,
              customFieldSetGroupIndex: 0,
              indexInList: 0,
              customFieldKey: item.fields[i].fieldKey,
            };
            this.deposit.updateField.customFieldValues.push(customObj);
          }
        });
      }
      this.deposit.updateField.entityKey =
        this.accountDetails.savingsAccountKey;
      this.deposit.updateField.entityTypeKey =
        this.accountDetails.savingsProductKey;
      // this.deposit.createdBy = this.accountDetails.createdBy;
      this.deposit.updatedBy = this.accountDetails.updatedBy;
      this.deposit.product = this.accountDetails.productName;
      this.deposit.savingsAccountKey = this.accountDetails.savingsAccountKey;
      this.deposit.createdBy = this.accountDetails.createdBy;
      // this.deposit.createdBy = 'Jude';
      this.addField.addField.entityKey = this.accountDetails.savingsAccountKey;
      this.addField.addField.entityTypeKey =
        this.accountDetails.savingsProductKey;
      let formData = {};
      formData.dailyWithdrawalLimit = this.accountDetails.dailyWaithdrawalLimit;

      //Remove this line when access team is done
      const { assignedUserKey, assignedCentreKey, createdBy, updatedBy, maximumWithdrawalAmount, ...newAccountDetails } = this.accountDetails; //eslint-disable-line
      if (this.addField.addField.customFieldValues.length > 0) {
        formData = {
          ...this.deposit,
          ...newAccountDetails,
          ...this.addField,
          targetAmount: this.accountDetails.savingsGoalTarget,
        };
      } else
        formData = {
          ...this.deposit,
          ...newAccountDetails,
          targetAmount: this.accountDetails.savingsGoalTarget,
        };
      if (this.accountDetails.interestRateTerm !== "Fixed") {
        this.loading = true;
        ApiService.put("DepositAccount/EditDepositAccount", formData)
          .then(() => {
            this.loading = false;
            let desc = "Account succesfully updated!";
            this.alertSuccess(desc);
            this.closeEditCommentModal();
            this.closeUpdateComment();
            this.showEditAccountModal = false;
            this.getState();
            this.getAccountDetails();
            this.getComment();
            this.getAccountField();
            this.getFilteredCustomers();
            this.getActivities();
            this.getAllTransactions();
          })
          .catch((error) => {
            this.loading = false;
            this.alertError(error.response.data.message);
            this.closeUpdateComment();
          });
      } else {
        if (
          this.accountDetails.accountType == "Fixed Deposit" ||
          this.accountDetails.accountType == "FixedDeposit"
        ) {
          if (this.numberIsValidFD()) {
            return;
          }
        }
        this.interestError = [];
        if (
          this.accountDetails.interestRate <
            this.accountDetails.minimumInterestrate ||
          this.accountDetails.interestRate >
            this.accountDetails.maximumInterestrate
        ) {
          this.alertError(
            `Interest rate must be between ${this.accountDetails.minimumInterestrate} and ${this.accountDetails.maximumInterestrate}`
          );
          this.interestError.push(
            `Interest must be between ${this.accountDetails.minimumInterestrate} and ${this.accountDetails.maximumInterestrate}`
          );
        }
        if (!this.interestError.length) {
          this.loading = true;
          ApiService.put("DepositAccount/EditDepositAccount", formData)
            .then(() => {
              this.loading = false;
              let desc = "Account succesfully updated!";
              this.alertSuccess(desc);
              this.closeEditAccountModal();
              this.showEditAccountModal = false;
              this.getState();
              this.getAccountDetails();
              this.getComment();
              this.getAccountField();
              this.getFilteredCustomers();
              this.getActivities();
              this.getAllTransactions();
            })
            .catch((error) => {
              this.loading = false;
              this.alertError(error.response.data.message);
            });
        }
      }
      e.preventDefault();
    },
    openAddFieldModal() {
      this.fieldDataType = "";
      this.showAddField = true;
    },
    closeAddFieldModal() {
      this.showAddField = false;
      this.resetCustomField();
      this.editingCustomField.editingCustomField = false;
    },
    getFieldValueDataType(data, key) {
      this.customFieldValue = "";
      this.searchValue = [];
      const getField = data.find((field) => field.customFieldKey == key);
      this.fieldDataType = getField.dataType;

      if (getField.dataType == "Selection") {
        this.selectionFieldValues = getField.customFieldSelections;
      }
      if (getField.dataType == "Checkbox") {
        this.customFieldValue = false;
      }
    },
    validateNumber(num) {
      if (isNaN(parseFloat(num))) {
        this.fieldNumberErr = true;
        return true;
      } else {
        this.fieldNumberErr = false;
        return false;
      }
    },
    editAccountValidateNumber(num, fieldSetIndex, fieldIndex) {
      if (isNaN(parseFloat(num))) {
        this.alertWarning("Invalid input value, Number required.");
        this.accountDetails.customefield.items[fieldSetIndex].fields[
          fieldIndex
        ].value = 0;
      }
    },
    async querySearch(query) {
      // if(["customer link", "group link"].includes(this.fieldDataType.toLowerCase())) {
      let searchQuery = query;
      this.searchValue = [];
      let searchArea =
        this.fieldDataType.toLowerCase() == "customer link"
          ? "customer"
          : this.fieldDataType.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`, {
          searchQuery,
          searchCategory: searchArea,
        });
        this.searchValue = response.data.data;
      } catch (e) {
        this.searchValue = [];
      }
      // }
    },
    async editAccountQuerySearch(query, fieldSetIndex, fieldIndex) {
      let arr = this.searchValue;
      let searchQuery = query;
      let searchArea =
        this.accountDetails.customefield.items[fieldSetIndex].fields[
          fieldIndex
        ].dataType.toLowerCase() == "customer link"
          ? "customer"
          : this.accountDetails.customefield.items[fieldSetIndex].fields[
              fieldIndex
            ].dataType.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`, {
          searchQuery,
          searchCategory: searchArea,
        });
        this.searchValue = response.data.data;
      } catch (e) {
        this.searchValue = arr;
      }
    },
    assignFieldValue(value) {
      this.customFieldValue = value.linkKey;
    },
    editAssignFieldValue(value) {
      this.editingCustomField.customFieldValue = value.linkKey;
    },
    getAccountField() {
      this.allAccountField = [];
      ApiService.post("DepositAccount/AllDepositAccountField", this.field)
        .then((response) => {
          this.allAccountField = response.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    saveCustomField() {
      if (this.fieldDataType == "Number") {
        if (this.validateNumber(this.customFieldValue)) {
          this.alertWarning("Invalid input type, number required.");
          return;
        }
      }
      this.loading = true;
      let formData = {
        url: "",
        entityKey: this.accountDetails.savingsAccountKey,
        entityTypeKey: this.accountDetails.savingsProductKey,
        customFieldValues: [
          {
            customFieldKey: this.customFieldKey,
            value: this.customFieldValue,
            indexInList: 0,
            customFieldSetGroupIndex: 0,
          },
        ],
      };
      ApiService.post("DepositAccount/AddField", formData)
        .then(() => {
          this.loading = false;
          // this.alertSuccess(response.data.message);
          this.alertSuccess("Successfully added field");
          this.closeAddFieldModal();
          this.getAccountDetails();
          this.getState();
          this.resetCustomField();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    updateCustomField() {
      this.checkCustomFieldError();
      if (this.fieldDataType == "Number") {
        if (this.validateNumber(this.editingCustomField.customFieldValue)) {
          this.alertWarning("Invalid input type, number required.");
          return;
        }
      }

      if (!this.customFieldError) {
        this.loading = true;
        let formData = {
          url: "",
          entityKey: this.accountDetails.savingsAccountKey,
          entityTypeKey: this.accountDetails.savingsProductKey,
          customFieldValues: [
            {
              customFieldKey: this.editingCustomField.customFieldKey,
              value: this.editingCustomField.customFieldValue,
              indexInList: 0,
              customFieldSetGroupIndex: 0,
            },
          ],
        };
        ApiService.put("DepositAccount/UpdateField", formData)
          .then(() => {
            this.loading = false;
            this.alertSuccess("Custom field updated successfully");
            this.closeAddFieldModal();
            this.getAccountDetails();
            this.getState();
            this.resetCustomField();
          })
          .catch((error) => {
            this.loading = false;
            this.alertError(error.response.data.message);
          });
      }
    },
    openTransferModal() {
      this.showTransferModal = true;
    },
    closeTransferModal() {
      this.showTransferModal = false;
    },
    openMakeTransferModal() {
      this.showTransferModal = true;
    },
    closeMakeTransferModal() {
      this.showTransferModal = false;
    },
    openWithdrawModal() {
      this.showWithdrawalModal = true;
      this.makeWithdraw.amount = this.maxWithdrawalAmount;
    },
    closeWithdrawModal() {
      this.showWithdrawalModal = false;
    },
    openDepositModal() {
      this.showDepositStatus = true;
      this.makeDeposit.amount = this.recommendedDepositAmount;
    },
    closeDepositModal() {
      this.showDepositStatus = false;
    },
    resetMakeDepositForm() {
      this.makeDeposit = {
        savingAccountKey: this.$route.params.id,
        amount: "",
        backDate: "",
        financialGl: "",
        notes: "",
      };
    },
    makeDepositTransaction() {
      this.loading = true;
      ApiService.post("DepositAccount/depositaction", this.makeDeposit)
        .then((response) => {
          this.loading = false;
          this.alertSuccess(response.data.message);
          this.closeDepositModal();
          this.resetMakeDepositForm();
          this.getAccountDetails();
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    resetMakeWithdrawalForm() {
      this.makeWithdraw = {
        savingAccountKey: this.$route.params.id,
        amount: "",
        backDate: "",
        financialGl: "",
        notes: "",
      };
    },
    makeWithdrawalTransaction() {
      this.loading = true;
      ApiService.post("DepositAccount/withdrawaction", this.makeWithdraw)
        .then((response) => {
          this.loading = false;
          this.alertSuccess(response.data.message);
          this.closeWithdrawModal();
          this.formattedWithAmount = null;
          this.showTransferModal = false;
          this.resetMakeWithdrawalForm();
          this.getAccountDetails();
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    getFilteredCustomers() {
      this.allCustomers = [];
      this.customerKey = null;
      GeneralApiService.post("Customer/customers", this.filterCustomers)
        .then((response) => {
          this.allCustomers = response.data.data.items;
          this.customerKey = "";
        })
        .catch((error) => {
          if (error.response.status != 409) {
            this.alertError(error.response.data.message);
          }
        });
    },
    getAllGroups() {
      GeneralApiService.post("Group/Groups", this.groupPage)
        .then((response) => {
          const data = response.data.data.items;
          this.allGroups.push(...data);
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getCustomerAccount() {
      this.customerAccounts = [];
      ApiService.get(
        `DepositAccount/GetDepositAccountByCustomer/${this.customerKey}`
      )
        .then((response) => {
          this.customerAccounts = response.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    makeTransfer() {
      this.loading = true;
      ApiService.post("DepositAccount/transferaction", this.transferAccount)
        .then((response) => {
          this.loading = false;
          this.alertSuccess(response.data.message);
          this.closeMakeTransferModal();
          this.resetTransferForm();
          this.getAccountDetails();
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    getActivities() {
      this.allActivities = [];
      ApiService.get(`DepositAccount/GetActivities/${this.$route.params.id}`)
        .then((response) => {
          this.allActivities = response.data;
        })
        .catch((error) => {
          this.alertError(error);
        });
    },
    openDisbursementModal(name) {
      this.showDisbursementModal = true;
      this.showCustomModal = name;
    },
    closeDisbursementModal() {
      this.showDisbursementModal = false;
      this.showCustomModal = "";
    },
    addFields() {
      this.customFieldValues.push({
        customFieldKey: this.showCustomModal.fields[0].fieldKey,
        value: this.customFieldValue,
        indexInList: 0,
        customFieldSetGroupIndex: 0,
      });
      this.showDisbursementModal = false;
      this.addField.addField.customFieldValues.push(...this.customFieldValues);
    },
    openAttachmentModal() {
      this.showAttachmentStatus = true;
    },
    closeAttachmentModal() {
      this.showAttachmentStatus = false;
      this.myFiles = "";
      this.title = "";
      this.description = "";
    },
    getAttachment() {
      this.attachments = [];
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByHolderkey?holderkey=${this.$route.params.id}&pageIndex=1&pageSize=10`
      )
        .then((resp) => {
          this.attachments = resp.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    onFileChange() {
      this.myFiles = this.$refs.myFiles.files[0];
      this.filename = this.$refs.myFiles.files[0].name;
      this.fileSize = this.$refs.myFiles.files[0].size;
    },
    removeFile() {
      this.filename = "";
      this.fileSize = 0;
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    uploadAttachment() {
      this.titleError = false;
      if (!this.title) {
        this.alertWarning("Document title is required.");
        this.titleError = true;
        return;
      }
      if (!this.myFiles) {
        this.alertWarning(
          "File attachment is required, you need to choose a file."
        );
        return;
      }
      const formData = new FormData();
      formData.append("file", this.myFiles);
      formData.append("documentTitle", this.title);
      formData.append("description", this.description);
      this.loading = true;
      ApiService.postFormData(
        `DepositAccount/UploadAttachment/${this.$route.params.id}?createdBy=${this.accountDetails.createdBy}&documentTitle=${this.title}`,
        formData
      )
        .then(() => {
          let desc = "File successully uploaded";
          this.alertSuccess(desc);
          this.loading = false;
          this.closeAttachmentModal();
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
          this.getAttachment();
          this.filename = "";
          this.fileSize = "";
          this.title = "";
          this.description = "";
          // this.$store.dispatch(GET_DEPOSIT_ACCOUNT, {
          //   savingsAccountKey: this.savingsAccountKey
          // });
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.alertError(error.response.data);
          } else {
            this.alertError(error.response.data.message);
          }
          this.loading = false;
        });
    },
    openDeleteAttachmentModal(del) {
      this.deleteAttachment = true;
      this.documentKey = del;
    },
    getAttachmentName(key) {
      this.attachmentName = "";
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByDocumentkey?documentKey=${key}`
      )
        .then((response) => {
          this.attachmentName = response.data.data.document_original_file_name;
          this.attachmentType = response.data.data.document_type;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    async downloadFile(key) {
      await this.getAttachmentName(key);
      ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.attachmentName || "file");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    togglePreviewModal() {
      this.showPreview = !this.showPreview;
    },
    previewFile(key) {
      this.loading = true;
      this.showPreview = !this.showPreview;
      ApiService.get(`GeneralDocument/PreviewDocument?documentKey=${key}`)
        .then((response) => {
          this.loading = false;
          this.imagePreviewDataType = response.data.data.fileType;
          this.imagePreviewDataName = response.data.data.fileUrl;
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    closeDeleteAttachmentModal() {
      this.deleteAttachment = false;
    },
    onAttachmentDelete() {
      this.loading = true;
      ApiService.delete(
        `GeneralDocument/delete?documentKey=${this.documentKey}`
      )
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loading = false;
          this.getComment();
          this.getAccountField();
          this.getFilteredCustomers();
          this.getActivities();
          this.getAllTransactions();
          this.getState();
          this.getAttachment();
          this.closeDeleteAttachmentModal();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    openMaturityModal() {
      this.showMaturityModal = true;
    },
    closeMaturityModal() {
      this.showMaturityModal = false;
    },
    addMaturityModal(value) {
      this.alertSuccess(value);
      this.getAccountDetails();
      this.closeMaturityModal();
      this.getState();
    },
    errorInMaturity(value) {
      this.alertError(value);
    },
    undoBeginMaturity() {
      this.showUndoMaturityModal = true;
    },
    closeUndoMaturityModal() {
      this.showUndoMaturityModal = false;
    },
    undoMaturityModal() {
      this.alertSuccess("Undo maturity period successful");
      this.getAccountDetails();
      this.closeUndoMaturityModal();
      this.getState();
    },
    errorInUndo(value) {
      this.alertError(value);
      this.closeUndoMaturityModal();
    },
    openNoApplyFeeModal() {
      if (!this.isApplyFeeActive) this.showNoApplyFee = true;
      else this.showApplyFee = true;
    },
    closeNoApplyFeeModal() {
      this.showNoApplyFee = false;
      this.showApplyFee = false;
    },
    saveApplyFee(value) {
      this.alertSuccess(value);
      this.closeApplyFeeModal();
      this.getComment();
      this.getAccountField();
      this.getFilteredCustomers();
      this.getActivities();
      this.getAllTransactions();
      this.getState();
      // this.$store.dispatch(GET_DEPOSIT_ACCOUNT, {
      //   savingsAccountKey: this.savingsAccountKey
      // });
    },
    errorAddingFee(value) {
      this.alertError(value);
    },
    handleInterestAddition(value) {
      this.alertSuccess(value);
      this.closeNoApplyFeeModal();
      this.showApplyModal = !this.showApplyModal;
      this.getAccountDetails();
      this.getComment();
      this.getAccountField();
      this.getFilteredCustomers();
      this.getActivities();
      this.getAllTransactions();
      this.getState();
    },
    handleInterestFailed(value) {
      this.alertError(value);
    },
    getAllTransactions() {
      this.$store.dispatch(GET_ALL_TRANSACTIONS, {
        pageindex: this.pages,
        pagesize: this.size,
        accountKey: this.$route.params.id,
        transactionStatus: this.transactionStatus,
      });
    },
    getUpdatedDeposits(value) {
      this.size = value;
      this.getAllTransactions();
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        let nextPage = this.transactions.hasNext
          ? this.transactions.currentPage + 1
          : 0;
        this.pages = nextPage;
        this.size = pageSize;
        this.getAllTransactions();
      } else if (pages === "previous") {
        let previousPage = this.transactions.hasPrevious
          ? this.transactions.currentPage - 1
          : 0;
        this.pages = previousPage;
        this.size = pageSize;
        this.getAllTransactions();
      } else if (pages !== null) {
        this.pages = pages;
        this.size = pageSize;
        this.getAllTransactions();
      }
    },
    getAccountOfficer(event) {
      this.accountDetails.assignedBranchKey = event.branchKey;
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.accountDetails.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },
    getAssignedUser(event) {
      this.accountDetails.assignedUserKey = event.userKey;
    },
    getTransactionByKey(value) {
      ApiService.get(`Transactions/GetTransactions/${value}`)
        .then((response) => {
          this.transactionValue = response.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.data);
        });
    },
    openTransactionView(value) {
      this.transactionValue = {};
      this.showTransactViewModal = true;
      this.getTransactionByKey(value);
    },
    closeTransactionView() {
      this.transactionValue = {};
      this.showTransactViewModal = false;
    },
    deleteTransaction(value) {
      this.selectedTransactionKey = value;
      this.showAdjustTransactionModal = true;
      this.getTransactionByKey(value);
    },
    changeTransactionStatus() {
      this.transactionStatus = !this.transactionStatus;
      this.getAllTransactions();
    },
    closeAdjustFeeModal() {
      this.showAdjustTransactionModal = false;
    },
    adjustFee(value) {
      this.alertSuccess(value);
      this.closeAdjustFeeModal();
      this.getComment();
      this.getAccountField();
      this.getFilteredCustomers();
      this.getActivities();
      this.getAllTransactions();
      this.getState();
      // this.$store.dispatch(GET_DEPOSIT_ACCOUNT, {
      //   savingsAccountKey: this.savingsAccountKey
      // });
    },
    errorAdjustFee(value) {
      this.alertError(value);
    },
    editCustomField(key, keySub, val, req, def, data, isEditing) {
      const getField = data.find((field) => field.fieldKey == keySub);
      this.fieldDataType = getField.dataType;

      if (getField.dataType == "Selection") {
        // this.selectionFieldValues = getField.customFieldSelections;
        const getFieldData = this.allAccountField.find(
          (field) => field.customFieldSetKey == key
        );
        const getSelectedField = getFieldData.customFields.find(
          (field) => field.customFieldKey == keySub
        );
        this.selectionFieldValues = getSelectedField.customFieldSelections;
      }
      if (
        ["customer link", "group link", "user link"].includes(
          this.fieldDataType.toLowerCase()
        )
      ) {
        // this.querySearch(val)
        val = getField.linkKey;
        this.linkFieldValue = {};
        this.linkFieldValue = {
          ...this.linkFieldValue,
          name: getField.linkName,
          linkKey: getField.linkKey,
        };
        this.querySearch(getField.linkName.split(" ")[0]);
      }
      this.editingCustomField.customFieldSetKey = key;
      this.editingCustomField.customFieldKey = keySub;
      this.editingCustomField.customFieldValue = val;
      this.editingCustomField.customField = data;
      this.editingCustomField.isEditing = isEditing;
      this.editingCustomField.isRequired = req;
      this.editingCustomField.isDefault = def;
      // this.openAddFieldModal();
      this.showAddField = true;
    },
    checkCustomFieldError() {
      if (
        this.editingCustomField.isRequired &&
        this.editingCustomField.customFieldValue === ""
      ) {
        this.customFieldError = "This field is required";
        return;
      }
      this.customFieldError = "";
    },
    getTransactionStatus(transactionType, transactionMode) {
      if (transactionType && transactionMode) {
        if (transactionType === "transfer" && transactionMode === "CREDIT") {
          return "text--success";
        }
        if (transactionType === "transfer" && transactionMode === "DEBIT") {
          return "text--danger";
        }
        return "";
      }
      return "";
    },
  },
  created() {
    this.getAccountDetails();
    this.getComment();
    this.getAccountField();
    this.getFilteredCustomers();
    this.getAllTransactions();
    this.getState();
    this.getAllGroups();
  },
  mounted() {
    this.getAttachment();
    this.getActivities();
    this.listBranches().then((res) => {
      this.loadingBranchList = false;
      if (res.status === 200) {
        this.branchList = res.data.data.items;
      }
    });
    // this.formattedWithAmount = this.inputCurrencyFormat(this.accountDetails.maxWithdrawalAmount);
    this.formattedAmountPaid = this.inputCurrencyFormat(
      this.accountDetails.recommendedDepositAmount
    );
  },
  /* eslint-disable */
  watch: {
    $route(to, from) {
      this.getAccountDetails();
    },
    showDepositStatus: function(newVal) {
      if (newVal) return this.formattedAmountPaid = this.inputCurrencyFormat(this.accountDetails.recommendedDepositAmount);
    },
    accountDetails: function(newVal) {
      if (newVal) this.getCustomerLoanDeposits(this.accountDetails.accountHolderKey)
    },
    transactions: function(val) {
      if (val) {
        this.allAdjusted = val.data.filter(item => !item.isAdjusted);
      }
    },
    // showWithdrawalModal: function(newVal) {
    //   if (newVal) return this.formattedWithAmount = this.inputCurrencyFormat(this.accountDetails.maxWithdrawalAmount);
    // },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    humanDate: function (date) {
      return date != null ? moment(date).fromNow() : "";
    },
    kb(val) {
      const sizes = ['bytes', 'KB', 'MB', 'GB'];
      if (val == 0) return '';
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i == 0) return `${val} ${sizes[i]}`
      return `${(val / (1024 ** i) ).toFixed(1)} ${sizes[i]}`;
    },
  },
};
</script>
<style scoped>
.text--capital span {
  font-size: 1.2rem;
}
.page__tabs2 {
  grid-template-columns: repeat(5, 1fr) !important;
}
.fitrem {
  min-width: 15rem;
}
.text--large {
  font-size: 2.4rem !important;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.text--primary {
  color: #F58634 !important;
}
.text--opacity {
  opacity: 0.5;
}
</style>
